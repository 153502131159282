import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject,of, Observable } from 'rxjs';
import { HttpClient, HttpResponse, HttpParams,HttpErrorResponse} from '@angular/common/http';
import { config } from './../../config';
import { DataTablesResponse } from '../../models/common';
import { FormField } from './model';
import { NgForm, FormsModule, FormBuilder,FormControl  } from '@angular/forms';
import { catchError, mapTo, tap, map } from 'rxjs/operators';


@Component({
  selector: 'app-data',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.css']
})


export class FormFieldComponent implements AfterViewInit, OnDestroy, OnInit {
	model = new FormField();
	dtResults: FormField[]
	filterForm;
	createForm;
	
	constructor(private http: HttpClient, private formBuilder: FormBuilder) {
	  this.model.setFormBuilder(formBuilder);
	  this.filterForm = this.model.getFilterForm();
	  this.createForm = this.model.getCreateForm();
	  //this.model.createFormMessage = 'sss';
	}
	
	ngOnInit() {
	  this.fetchCityList();
	  this.getList();
	  
	  //this.model.setErrors({'name':'Required Field'});
	}
  	  
	getFormView(id){
		this.model.createFormReset();
		this.model.isEdit = true;
	    this.model.id = id;
		this.http.get(`${config.apiUrl}/form/field/`+id).subscribe(resp => {
				this.model.createForm.controls['name'].disable();
				if(resp['options'] == null){resp['options']='';}
				else{
					resp['options'] = resp['options'].toString();
				}
				this.model.city_ids = resp['city_ids'];
				//console.log(this.model.city_ids);
				this.model.activity_checked_list = resp['activities'];
				this.model.createForm.patchValue(resp);
		  });
	}
	
	// Create Form
	insertFormData() {
		 //this.isSubmit = true;
		 var data = {}
		 if(this.model.isEdit){
			 //console.log(this.model.createForm.value.status);
			 data = {
					  //'name': this.model.createForm.value.name,
					  'label': this.model.createForm.value.label,
					  'tag': this.model.createForm.value.tag,
					  'type': this.model.createForm.value.type,
					  'priority': this.model.createForm.value.priority,
					  'options': this.model.createForm.value.options,
					  'status': Number(this.model.createForm.value.status),
					  'city_ids': this.model.city_ids.toString(),
					  'activities': this.model.activity_checked_list.toString(),
					  'required': this.model.createForm.value.required,
				  };
			  
			 //console.log(data);
			 this.http.put(`${config.apiUrl}/form/field/`+ this.model.id,data).subscribe(resp => {
				  //console.log(resp);
				  this.model.createFormMessage = "Form Field has been updated.";
				  this.model.setErrors({});
				  this.rerender();
			  },(error: HttpErrorResponse)=> {
				  this.model.setErrors(error.error.error);
		      })
		 }
		 else{
			 data = {
				  'name': this.model.createForm.value.name,
				  'label': this.model.createForm.value.label,
				  'tag': this.model.createForm.value.tag,
				  'type': this.model.createForm.value.type,
				  'priority': this.model.createForm.value.priority,
				  'options': this.model.createForm.value.options,
				  'status': Number(this.model.createForm.value.status),
				  'city_ids': this.model.city_ids.toString(),
				  'activities': this.model.activity_checked_list.toString(),
				  'required': this.model.createForm.value.required,
			  };
			  
			 
			 this.http.post(`${config.apiUrl}/form/field`,data).subscribe(resp => {
				  //console.log(resp);
				  this.model.createFormMessage = "Form Field has been created.";
				  this.model.setErrors({});
				  this.rerender();
			  },(error: HttpErrorResponse)=> {
				  this.model.setErrors(error.error.error);
		      })
		 }
		 
	 }
	
	
	 //For Filter form
	 onSearch(data) {
		  this.rerender();
	 }
	 changeCity(filter){
		  this.model.changeCity(filter);
		  this.rerender();
	 }
	  
	 fetchCityList(): void
	 {
		  this.http.get(`${config.apiUrl}/city/select`).subscribe(resp => {
			  this.model.setCityList(resp);
		  });
	 }  
  
  
	 //DataTable Fetures start.............. 
	 getList(): void {
		  const that = this;
		  this.dtOptions = {
			  pagingType: 'full_numbers',
			  lengthChange: false,
			  pageLength: 10,
			  search: false,
			  serverSide: true,
			  processing: true,
			  order: [0,1,1],
			  ajax: (dataTablesParameters: any, callback) => {
			    dataTablesParameters.search = this.model.getFilterdata().search;
			    dataTablesParameters.city_id = this.model.getFilterCityId();
			    
			    that.http.get<DataTablesResponse>(`${config.apiUrl}/form/field`, {params: dataTablesParameters }).subscribe(resp => {
			          that.dtResults = resp.data;
			
			          callback({
			            recordsTotal: resp.recordsTotal,
			            recordsFiltered: resp.recordsFiltered,
			            data: []
			          })
			      });
			  },
			  //columns: [{ data: 'id' },{ data: 'name' }, { data: 'code' },{ data: 'area' },{ data: 'zone' },{ data: 'address' }, { data: 'coardinates.latitude' },{ data: 'coardinates.longitude' },{ data: 'status'}],
			  columnDefs: [{orderable: false, targets: [0,1,2,3,4,5,6,7,8,9]}],
		  };
	  }
	 
	  @ViewChild(DataTableDirective, {static: false})
	  dtElement: DataTableDirective;
	  dtOptions: DataTables.Settings = {};
	  dtTrigger: Subject<any> = new Subject();
	  
	  ngAfterViewInit(): void { this.dtTrigger.next();}
	
	  ngOnDestroy(): void { this.dtTrigger.unsubscribe();}
	
	  rerender(): void { this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {dtInstance.draw();});}
	  //DataTable Fetures End.............. 
}
