export class Outlet {
id: string;
city_id: string;
name: string;
code: string;
area: string;
zone: string
address: string;
coardinates: string;
profile_pic: string;
status: number;
}


export class Form {
	id: string;
	city_id: string;
	name: string;
	code: string;
	area: string;
	zone: string
	address: string;
	coardinates: string;
	profile_pic: string;
	status: number;
	
	public formBuilder;
	public filterForm;
	public cityList;
	public filter_city_id = 0;
	
	public createForm;
	public city_ids = [];
	
	public errors = {};
	public createFormMessage: any;
	public createFormErrorMessage:any;
	
	search_fields = {
			search:'',
			fwp_code:'',
			outlet_code:'',
			city_id:0
	}
	
	public form_fields = {}
	
	
	
	public setFormBuilder(formBuilder){
		this.createFormMessage = false;
		this.createFormErrorMessage = false;
		
		this.formBuilder = formBuilder;
		this.filterForm = this.formBuilder.group(this.search_fields);
		
		this.createForm = this.formBuilder.group(this.form_fields);
		
		//this.formControl
	}
	
	public getFilterForm()
	{
		return this.filterForm;
	}
	
	public getFilterdata()
	{
		return this.filterForm.value;
	}
	
	public getCreateForm()
	{
		return this.createForm;
	}
	
	public getFormdata()
	{
		return this.createForm.value;
	}
	
	public setCityList(list){
		this.cityList = list
	}
	
	public getCity(){
		return this.cityList;
	}
	
	public changeCity(filter){
		//if(filter)this.city_id = this.filterForm.value.city_id;
		
		if(this.filterForm.value.city_id > 0){
			this.filter_city_id = this.cityList[this.filterForm.value.city_id - 1]['id'];
		}
		else
		{
			this.filter_city_id = 0
		}
	}
	
	public getFilterCityId()
	{
		return this.filter_city_id;
	}
	
	public setErrors(errors){
		this.errors = errors;
	}
	
	public hasError(key)
	{
		return this.errors[key];
	}
	
	public createFormReset()
	{
		this.createFormMessage = false;
		this.createFormErrorMessage = false;
		this.createForm = this.formBuilder.group(this.form_fields);
	}
	
	public onChecklistChange(checked, checkbox) {
		if(checked)
		{	
			//console.log(checkbox);
			this.city_ids.push(checkbox.id);
		}
		else
		{
			var index = this.city_ids.indexOf(checkbox.id);
			if (index !== -1) this.city_ids.splice(index, 1);
		}
	  }

	  
}
