import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject,of, Observable } from 'rxjs';
import { HttpClient, HttpResponse, HttpParams,HttpErrorResponse} from '@angular/common/http';
import { config } from '../../config';
import { DataTablesResponse } from '../../models/common';
import { Outlet } from './model';
import { NgForm, FormsModule, FormBuilder,FormControl  } from '@angular/forms';
import { catchError, mapTo, tap, map } from 'rxjs/operators';
//import {NgbDate, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import {formatDate} from '@angular/common';
import * as moment from 'moment';
import { DownloadService } from '../../download.service';

@Component({
  selector: 'app-data',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.css']
})


export class SupervisorActivityComponent implements AfterViewInit, OnDestroy, OnInit {
	model = new Outlet();
	dtResults: Outlet[];
	importData;
	filterForm;
	createForm;
	importForm;
	assignForm;
	
	dateLabel = 'Today';
	tempDateLabel;
	fromDate;
	toDate;
	
	datetime = Math.floor((Math.random() * 10) + 1);
	
	checkin_activities;
	activity_title = "Supervisor Checkin Activity";
	
	//Calender View start
	  selected: any;
	  alwaysShowCalendars: boolean;
	  showRangeLabelOnInput: boolean;
	  keepCalendarOpeningWithRange: boolean;
	  maxDate: moment.Moment;
	  minDate: moment.Moment;
	  invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];
	  ranges: any = {
	    Today: [moment(), moment()],
	    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
	    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
	    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
	    'This Month': [moment().startOf('month'), moment().endOf('month')],
	    'Last Month': [
	      moment()
	        .subtract(1, 'month')
	        .startOf('month'),
	      moment()
	        .subtract(1, 'month')
	        .endOf('month')
	    ]
	  };

	  isInvalidDate = (m: moment.Moment) =>  {
	    return this.invalidDates.some(d => d.isSame(m, 'day') )
	  }
	  rangeClicked(range) {
		  this.tempDateLabel = range.label;
		  //console.log('[rangeClicked] range is : ', range);
	  }
	  datesUpdated(range) {
		  if(range['startDate'] != null){
			  this.fromDate = range['startDate'].format("YYYY-MM-DD");
			  this.toDate = range['endDate'].format("YYYY-MM-DD");
			  this.rerender();
		  }
		  this.dateLabel = this.tempDateLabel;
		  //console.log('[datesUpdated] range is : ', range);
		 
	  }
	  setDate(d){
		  var range = {
			  'endDate':this.ranges[d][1],
			  'startDate':this.ranges[d][0]
		  }
		  
		  this.rangeClicked({'label':d,'dates':this.ranges[d]});
		  this.datesUpdated(range);
	  }
	//Calender View End
	
	
	constructor(private http: HttpClient, private formBuilder: FormBuilder,public downloadService: DownloadService) {
	  this.model.setFormBuilder(formBuilder);
	  this.filterForm = this.model.getFilterForm();
	  this.createForm = this.model.getCreateForm();
	  this.importForm = this.model.getImportForm();
	  this.assignForm = this.model.getAssignForm();
	  
	  
	  	this.fromDate = moment().format("YYYY-MM-DD");
		this.toDate = moment().format("YYYY-MM-DD");
		
	    this.alwaysShowCalendars = true;
	    this.keepCalendarOpeningWithRange = true;
	    this.showRangeLabelOnInput = true;
	    this.selected = this.ranges['Today'];
	    this.tempDateLabel = "Today";  
	}
	
	ngOnInit() {
	  this.fetchCityList();
	  this.getList();
	  this.fetchUserList();
	}
  	
	
	clearSearch(){
		this.model.filterForm.patchValue(this.model.search_fields);
		this.rerender();
	}
	
	//Assign outlet
	getAssignForm(id){
		this.model.assign_outlet_id = id;
	}
	
	//Assign Form
	changeSupervisor(){
		if(this.assignForm.value.supervisor != 0)
		{
			var supervisor = this.assignForm.value.supervisor;
			this.http.get(`${config.apiUrl}/user/select/`+supervisor).subscribe(resp => {
				  this.model.fwpList = resp;
			  });
		}
	}
	
	//Assign outlet
	assignOutlet(){
		this.model.createFormReset();
		  var r = {
				  'outlet_id': this.model.assign_outlet_id,
				  'user_id': this.assignForm.value.fwp
		  };
		  
		  //console.log(r);
		  
		  this.http.post(`${config.apiUrl}/outlet/assign`,r).subscribe(resp => {
			  this.model.createFormMessage = "Outlet has been assigned.";
		  },(error: HttpErrorResponse)=> {
			  this.model.setErrors(error.error.error);
	      })
	}
	
	//Import Outlet form submit
	importOutlet() {
		  this.model.createFormReset();
		  this.importData = false;
		  var r = {
				  //'city_id': this.importForm.value.city_id,
				  'outlet': this.model.importFile
		  };
		  
		  this.http.post(`${config.apiUrl}/outlet/route/import`,r).subscribe(resp => {
			 
			  if(resp['status'] == 'invalid'){
				  this.importData = resp['data'];
			  	  this.model.setErrors({'message':["Some outlet mapping is invalid."]});  
			  }  
			  else
				  this.model.createFormMessage = "Route Plan has been imported.";
		  },(error: HttpErrorResponse)=> {
			  this.model.setErrors(error.error.error);
	      })
	  }
	
	getFormView(id){
		this.model.createFormReset();
		this.model.isEdit = true;
		
	    this.model.id = id;
		this.http.get(`${config.apiUrl}/outlet/`+id).subscribe(resp => {
				this.model.createForm.controls['code'].disable();
				resp['latitude'] = resp['coardinates']['latitude'];
				resp['longitude'] = resp['coardinates']['longitude'];
				this.model.createForm.patchValue(resp);
		  });
	}
	
	 // Create Form
	insertFormData() {
		
		var data = {}
		if(this.model.isEdit){
			data = {
				  'name': this.model.createForm.value.name,
				  //'code': this.createForm.value.code,
				  'area': this.model.createForm.value.area,
				  'zone': this.model.createForm.value.zone,
				  'address': this.model.createForm.value.address,
				  'coardinates': {'latitude':this.model.createForm.value.latitude,'longitude':this.model.createForm.value.longitude},
				  'status': Number(this.model.createForm.value.status),
				  'city_id': this.createForm.value.city_id,
			 };
			 this.http.put(`${config.apiUrl}/outlet/`+ this.model.id,data).subscribe(resp => {
				  //console.log(resp);
				  this.model.createFormMessage = "Outlet has been updated.";
				  this.model.setErrors({});
				  this.rerender();
			  },(error: HttpErrorResponse)=> {
				  this.model.setErrors(error.error.error);
		      })
		 }
		else{
			data = {
				  'name': this.model.createForm.value.name,
				  'code': this.model.createForm.value.code,
				  'area': this.model.createForm.value.area,
				  'zone': this.model.createForm.value.zone,
				  'address': this.model.createForm.value.address,
				  'coardinates': {'latitude':this.model.createForm.value.latitude,'longitude':this.model.createForm.value.longitude},
				  'status': Number(this.model.createForm.value.status),
				  'city_id': this.model.createForm.value.city_id,
			 };
			  
			 //console.log(data);
			 this.http.post(`${config.apiUrl}/outlet`,data).subscribe(resp => {
				  //console.log(resp);
				  this.model.createFormMessage = "Outlet has been created.";
				  this.model.setErrors({});
			  },(error: HttpErrorResponse)=> {
				  this.model.setErrors(error.error.error);
		      })
		}
	 }
	
	
	
	 //For Filter form
	 onSearch(data) {
		  this.rerender();
	 }
	 
	 changeCity(filter){
		  //this.model.changeCity(filter);
		  if(filter)
		  this.rerender();
	 }
	 
	 fetchUserList(): void {
		  this.http.get(`${config.apiUrl}/user/select`).subscribe(resp => {
			  this.model.userList = resp;
		  });
	 }
	  
	 fetchCityList(): void
	 {
		  this.http.get(`${config.apiUrl}/city/select`).subscribe(resp => {
			  this.model.setCityList(resp);
		  });
	 }  
  
  
	 //DataTable Fetures start.............. 
	 getList(): void {
		  const that = this;
		  this.dtOptions = {
			  pagingType: 'full_numbers',
			  lengthChange: false,
			  pageLength: 10,
        //paging: false,
			  search: false,
			  serverSide: true,
			  processing: true,
			  order: [0,1,1],
			  ajax: (dataTablesParameters: any, callback) => {
			    dataTablesParameters.search = this.model.filterForm.value.search;
			    dataTablesParameters.sup_code = this.model.filterForm.value.fwp_code;
			    dataTablesParameters.name = this.model.filterForm.value.outlet_code;
			    dataTablesParameters.city_id = this.filterForm.value.city_id;
			    dataTablesParameters.fromDate = this.fromDate;
			    dataTablesParameters.toDate = this.toDate;
			    
			    //console.log(dataTablesParameters);
			    
			    that.http.get<DataTablesResponse>(`${config.apiUrl}/supervisor/web/activity`, {params: dataTablesParameters }).subscribe(resp => {
			          that.dtResults = resp.data;
			
			          callback({
			            recordsTotal: resp.recordsTotal,
			            recordsFiltered: resp.recordsFiltered,
			            data: []
			          })
			      });
			  },
			  //columns: [{ data: 'id' },{ data: 'name' }, { data: 'code' },{ data: 'area' },{ data: 'zone' },{ data: 'address' }, { data: 'coardinates.latitude' },{ data: 'coardinates.longitude' },{ data: 'status'}],
			  columnDefs: [{orderable: false, targets: [0,1,2,3]}],
		  };
	  }
	 
	  @ViewChild(DataTableDirective, {static: false})
	  dtElement: DataTableDirective;
	  dtOptions: DataTables.Settings = {};
	  dtTrigger: Subject<any> = new Subject();
	  
	  ngAfterViewInit(): void { this.dtTrigger.next();}
	
	  ngOnDestroy(): void { this.dtTrigger.unsubscribe();}
	
	  rerender(): void { this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {dtInstance.draw();});}
	  //DataTable Fetures End.............. 
	  
	  
	  checkinActivity(outletId){
		  this.activity_title = "Supervisor Checkin Activity";
		  this.http.get(`${config.apiUrl}/supervisor/web/checkins/`+outletId).subscribe(resp => {
			  this.checkin_activities = resp;
		  });
      }
	  
	  
	  
	  
	  setStatus(id,status){
		  this.model.createFormReset();
		  this.model.statusForm.patchValue({'id':id,'status':status});
	  }
	  changeStatus(){
			 
	        var data = {'id':this.model.statusForm.value.id,'status':this.model.statusForm.value.status}	
			  
			this.http.post(`${config.apiUrl}/outlet/web/route/status`,data).subscribe(resp => {
				  this.model.createFormMessage = "Route plan has been updated.";
				  this.model.setErrors({});
				  this.rerender();
			  });
	        return false;
	 }
}
