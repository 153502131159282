import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject,of, Observable } from 'rxjs';
import { HttpClient, HttpResponse, HttpParams,HttpErrorResponse} from '@angular/common/http';
import { config } from './../../config';
import { DataTablesResponse } from '../../models/common';
import { Outlet } from './model';
import { NgForm, FormsModule, FormBuilder,FormControl  } from '@angular/forms';
import { catchError, mapTo, tap, map } from 'rxjs/operators';
import * as moment from 'moment';
import { DownloadFileService } from './../../download.service';

@Component({
	  selector: 'app-data',
	  templateUrl: './outlet.component.html',
	  styleUrls: ['./outlet.component.css']
	})



export class ReportOutletComponent implements AfterViewInit, OnDestroy, OnInit {
	model = new Outlet();
	downf = new DownloadFileService();
    dtResults: Outlet[]
	filterForm;
	createForm;
	
	downloadURL = `${config.apiUrl}/report/download/`
	
	dateLabel = 'Today';
	tempDateLabel;
	fromDate;
	toDate;
	
	//Calender View start
	  selected: any;
	  alwaysShowCalendars: boolean;
	  showRangeLabelOnInput: boolean;
	  keepCalendarOpeningWithRange: boolean;
	  maxDate: moment.Moment;
	  minDate: moment.Moment;
	  invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];
	  ranges: any = {
	    Today: [moment(), moment()],
	    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
	    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
	    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
	    'This Month': [moment().startOf('month'), moment().endOf('month')],
	    'Last Month': [
	      moment()
	        .subtract(1, 'month')
	        .startOf('month'),
	      moment()
	        .subtract(1, 'month')
	        .endOf('month')
	    ]
	  };

	  isInvalidDate = (m: moment.Moment) =>  {
	    return this.invalidDates.some(d => d.isSame(m, 'day') )
	  }
	  rangeClicked(range) {
		  this.tempDateLabel = range.label;
		  //console.log('[rangeClicked] range is : ', range);
	  }
	  datesUpdated(range) {
		  if(range['startDate'] != null){
			  this.fromDate = range['startDate'].format("YYYY-MM-DD");
			  this.toDate = range['endDate'].format("YYYY-MM-DD");
			
		  }
		  this.dateLabel = this.tempDateLabel;
		  //console.log('[datesUpdated] range is : ', range);
		 
	  }
	  setDate(d){
		  var range = {
			  'endDate':this.ranges[d][1],
			  'startDate':this.ranges[d][0]
		  }
		  
		  this.rangeClicked({'label':d,'dates':this.ranges[d]});
		  this.datesUpdated(range);
	  }
	  //Calender View End
	  
	  
	constructor(private http: HttpClient, private formBuilder: FormBuilder) {
		this.model.setFormBuilder(formBuilder);
		this.filterForm = this.model.getFilterForm();
		this.createForm = this.model.getCreateForm();
	  
	  
	    this.fromDate = moment().format("YYYY-MM-DD");
		this.toDate = moment().format("YYYY-MM-DD");
		
	    this.alwaysShowCalendars = true;
	    this.keepCalendarOpeningWithRange = true;
	    this.showRangeLabelOnInput = true;
	}
	
	
	ngOnInit() {
	  this.getList();
	}
  	
    downloadActiveOutletReport(filename) {
         var DocFile = filename.slice(0, -5);  
		 this.http.get(`${config.apiUrl}/report/download/`+filename, {
          responseType: 'blob'} 
         ).subscribe(response => this.downf.downLoadFile(response, " application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"))
    }
	
	// Create Form
	insertFormData() {
		
		 var data = {
			  'fromDate': this.fromDate,
			  'toDate': this.fromDate
		 };
		  
		 //console.log(data);
		 this.http.post(`${config.apiUrl}/report/activeOutlet`,data).subscribe(resp => {
			  //console.log(resp);
			  this.model.createFormMessage = "Active Outlet Report has been requested.";
			  this.model.setErrors({});
			  this.rerender();
		  },(error: HttpErrorResponse)=> {
			  this.model.setErrors(error.error.error);
	      })
	 }
	
	
	
	 //For Filter form
	 onSearch(data) {
		  this.rerender();
	 }
	 
  
	 //DataTable Fetures start.............. 
	 getList(): void {
		  const that = this;
		  this.dtOptions = {
			  pagingType: 'full_numbers',
			  lengthChange: false,
			  pageLength: 10,
			  search: false,
			  serverSide: true,
			  processing: true,
			  order: [0,1,1],
			  ajax: (dataTablesParameters: any, callback) => {
			    dataTablesParameters.search = this.model.filterForm.value.search;
			    
			    that.http.get<DataTablesResponse>(`${config.apiUrl}/report/activeOutlet`, {params: dataTablesParameters }).subscribe(resp => {
			          that.dtResults = resp.data;
			
			          callback({
			            recordsTotal: resp.recordsTotal,
			            recordsFiltered: resp.recordsFiltered,
			            data: []
			          })
			      });
			  },
			  //columns: [{ data: 'id' },{ data: 'name' }, { data: 'code' },{ data: 'area' },{ data: 'zone' },{ data: 'address' }, { data: 'coardinates.latitude' },{ data: 'coardinates.longitude' },{ data: 'status'}],
			  columnDefs: [{orderable: false, targets: [0,1,2,3,4,5]}],
		  };
	  }
	 
	  @ViewChild(DataTableDirective, {static: false})
	  dtElement: DataTableDirective;
	  dtOptions: DataTables.Settings = {};
	  dtTrigger: Subject<any> = new Subject();
	  
	  ngAfterViewInit(): void { this.dtTrigger.next();}
	
	  ngOnDestroy(): void { this.dtTrigger.unsubscribe();}
	
	  rerender(): void { this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {dtInstance.draw();});}
	  //DataTable Fetures End.............. 
}
