export class FormField {
	public id: string;
	city_id: string;
	name: string;
	code: string;
	area: string;
	zone: string
	address: string;
	coardinates: string;
	profile_pic: string;
	status: number;
	
	public formBuilder;
	public filterForm;
	public cityList;
	public activityList = ['MI','LAMPS','NOW', 'RAP'];
	public filter_city_id = 0;
	
	public createForm;
	public city_ids = [];
	public activity_checked_list = [];
	
	public isEdit = false;
	
	public errors = {};
	public createFormMessage: any;
	
	
	search_fields = {
			search:'',
			city_id:0
	}
	
	form_fields = {
			name:'',
			label:'',
			tag:'',
			type:'input',
			options:'',
			validation:{},
			step:1,
			priority:0,
			status:1,
			city_id:0,
			required:0,
			city_ids:{},
			city:1
	}
	
	public setFormBuilder(formBuilder){
		this.createFormMessage = false;
		
		this.formBuilder = formBuilder;
		this.filterForm = this.formBuilder.group(this.search_fields);
		
		this.createForm = this.formBuilder.group(this.form_fields);
		
		//this.formControl
	}
	
	public getFilterForm()
	{
		return this.filterForm;
	}
	
	public getFilterdata()
	{
		return this.filterForm.value;
	}
	
	public getCreateForm()
	{
		return this.createForm;
	}
	
	public getFormdata()
	{
		return this.createForm.value;
	}
	
	public setCityList(list){
		this.cityList = list
	}
	
	public getCity(){
		return this.cityList;
	}
	
	public changeCity(filter){
		//if(filter)this.city_id = this.filterForm.value.city_id;
		
		if(this.filterForm.value.city_id > 0){
			this.filter_city_id = this.cityList[this.filterForm.value.city_id - 1]['id'];
		}
		else
		{
			this.filter_city_id = 0
		}
	}
	
	public getFilterCityId()
	{
		return this.filter_city_id;
	}
	
	public setErrors(errors){
		this.errors = errors;
	}
	
	public hasError(key)
	{
		return this.errors[key];
	}
	
	public createFormReset()
	{
		this.isEdit = false;
		this.createForm.controls['name'].enable();
		this.errors = {};
		this.createFormMessage = false;
		this.createForm.patchValue(this.form_fields);
		this.city_ids = [];
	}
	
	public onChecklistChange(checked, checkbox) {
		//console.log(checked);
		//console.log(checkbox);
		if(checked)
		{	
			//console.log(checkbox);
			this.city_ids.push(checkbox.id);
		}
		else
		{
			var index = this.city_ids.indexOf(checkbox.id);
			if (index !== -1) this.city_ids.splice(index, 1);
		}
		//console.log(this.city_ids);
	  }
	
	
	public onActivityChecklistChange(checked, checkbox) {
		//console.log(checked);
		//console.log(checkbox);
		if(checked)
		{	
			//console.log(checkbox);
			this.activity_checked_list.push(checkbox);
		}
		else
		{
			var index = this.activity_checked_list.indexOf(checkbox);
			if (index !== -1) this.activity_checked_list.splice(index, 1);
		}
		//console.log(this.activity_checked_list);
	  }

	  
}
