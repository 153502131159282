export const config = {
  // apiUrl: 'https://www.stepupforward.com/api/test/'
  // apiUrl: 'http://3.108.174.220:5002'
  apiUrl: 'https://api.stepupforward.com/v1'
  // apiUrl: 'http://localhost:5002'
}


// 8802274880
// Test@1234
// Arcww@1234
