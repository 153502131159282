import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject,of, Observable } from 'rxjs';
import { HttpClient, HttpResponse, HttpParams,HttpErrorResponse} from '@angular/common/http';
import { config } from './../../config';
import { DataTablesResponse } from '../../models/common';
import { Productivity } from './model';
import { NgForm, FormsModule, FormBuilder,FormControl  } from '@angular/forms';
import { catchError, mapTo, tap, map } from 'rxjs/operators';
import * as moment from 'moment';
import { DownloadFileService } from './../../download.service';

@Component({
  selector: 'app-data',
  templateUrl: './productivity.component.html',
  styleUrls: ['./productivity.component.css']
})


export class ReportProductivityComponent implements AfterViewInit, OnDestroy, OnInit {
	model = new Productivity();
    downf = new DownloadFileService();
	dtResults: Productivity[]
	filterForm;
	createForm;
	monthList = [];
	
	downloadURL = `${config.apiUrl}/report/download/`
	
	constructor(private http: HttpClient, private formBuilder: FormBuilder) {
	  this.model.setFormBuilder(formBuilder);
	  this.filterForm = this.model.getFilterForm();
	  this.createForm = this.model.getCreateForm();
	}
	
	ngOnInit() {
		this.getMonthList();
		this.getList();
	}
  	
	downloadProductivityReport(filename) {
        this.http.get(`${config.apiUrl}/report/download/`+filename, {
          responseType: 'blob'} 
         ).subscribe(response => this.downf.downLoadFile(response, " application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"))
    }
    
	// Create Form
	insertFormData() {
		 //this.isSubmit = true;
		 var data = {}
		 
		 data = {
			  'date': this.model.createForm.value.date
		 };
		  
		 //console.log(data);
		 this.http.post(`${config.apiUrl}/report/productivity`,data).subscribe(resp => {
			  //console.log(resp);
			  this.model.createFormMessage = "Productivity Report has been requested.";
			  this.model.setErrors({});
			  this.rerender();
		  },(error: HttpErrorResponse)=> {
			  this.model.setErrors(error.error.error);
	      })
	 }
	
	
	
	 //For Filter form
	 onSearch(data) {
		  this.rerender();
	 }
	 
  
	 //DataTable Fetures start.............. 
	 getList(): void {
		  const that = this;
		  this.dtOptions = {
			  pagingType: 'full_numbers',
			  lengthChange: false,
			  pageLength: 10,
			  search: false,
			  serverSide: true,
			  processing: true,
			  order: [0,1,1],
			  ajax: (dataTablesParameters: any, callback) => {
			    dataTablesParameters.search = this.model.filterForm.value.search;
			    
			    that.http.get<DataTablesResponse>(`${config.apiUrl}/report/productivity`, {params: dataTablesParameters }).subscribe(resp => {
			          that.dtResults = resp.data;
			
			          callback({
			            recordsTotal: resp.recordsTotal,
			            recordsFiltered: resp.recordsFiltered,
			            data: []
			          })
			      });
			  },
			  //columns: [{ data: 'id' },{ data: 'name' }, { data: 'code' },{ data: 'area' },{ data: 'zone' },{ data: 'address' }, { data: 'coardinates.latitude' },{ data: 'coardinates.longitude' },{ data: 'status'}],
			  columnDefs: [{orderable: false, targets: [0,1,2,3,4]}],
		  };
	  }
	  
	 getMonthList(){
		 var startDate = moment('2020-01-01');
		 var endDate = moment(moment().add(1, 'month').format("YYYY-MM-01"))
		
		 this.model.createForm.patchValue({"date": moment().format("YYYY-MM-01")});
		 this.model.form_fields['date'] = moment().format("YYYY-MM-01");
		 
		 var result = [];
	
		 if (endDate.isBefore(startDate)) {
		   throw "End date must be greated than start date."
		 }      
	
		 while (startDate.isBefore(endDate)) {
			 let t = {'id':startDate.format("YYYY-MM-01"),'value':startDate.format("MMMM YYYY")}
			 result.push(t);
			 startDate.add(1, 'month');
		 }
		 this.monthList = result.reverse();
		 //$('#result').text("[" + result.join(",") + "]")
	 }
	  @ViewChild(DataTableDirective, {static: false})
	  dtElement: DataTableDirective;
	  dtOptions: DataTables.Settings = {};
	  dtTrigger: Subject<any> = new Subject();
	  
	  ngAfterViewInit(): void { this.dtTrigger.next();}
	
	  ngOnDestroy(): void { this.dtTrigger.unsubscribe();}
	
	  rerender(): void { this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {dtInstance.draw();});}
	  //DataTable Fetures End.............. 
}
