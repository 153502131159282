export class User {
	public id: string;
	city_id: string;
	code: string;
	name: string;
	email: string;
	password: string;
	mobile: number;
	status: number;
	isLocked: number;
	profile_pic: string;
	parent_id: string;
	role: number;
	
	public formBuilder;
	
	public cityList;
	public userList;
	public supervisorList = [];
	public fwpList:any;
	
	public city_manager_id = 0;
	
	public filterForm;
	public createForm;
	public importForm;
	public parentForm;
	
	
	public isEdit:boolean = false;
	
	public importFile;
	
	public errors = {};
	public createFormMessage: any;
	
	search_fields = {
			search:'',
			city_manager:0,
			supervisor:0
	}
	
	import_fields = {
			file:'',
			city_manager:0,
			supervisor:0,
			city_id:0
	}
	
	
	
	form_fields = {
			city_id:0,
			city_manager:0,
			supervisor:0,
			name:'',
			code:'',
			email:'',
			password:'',
			confirmPassword:'',
			mobile:'',
			profile_pic:'',
			status:1,
			isLocked:0,
			parent_id:'',
			role: 1
	}
	parent_fields = {
			city_manager:0,
			supervisor:0,
			id:0
	}
	
	
	
	public setFormBuilder(formBuilder){
		this.createFormMessage = false;
		
		this.formBuilder = formBuilder;
		this.filterForm = this.formBuilder.group(this.search_fields);
		
		this.createForm = this.formBuilder.group(this.form_fields);
		
		this.importForm = this.formBuilder.group(this.import_fields);
		
		this.parentForm = this.formBuilder.group(this.parent_fields);
	}
	
	public getFilterForm()
	{
		return this.filterForm;
	}
	
	public getFilterdata()
	{
		return this.filterForm.value;
	}
	
	public getCreateForm()
	{
		return this.createForm;
	}
	
	public getFormdata()
	{
		return this.createForm.value;
	}
	
	
	public getImportForm()
	{
		return this.importForm;
	}
	
	public getImportdata()
	{
		return this.importForm.value;
	}
	
	
	public setErrors(errors){
		this.errors = errors;
	}
	
	public hasError(key)
	{
		return this.errors[key];
	}
	
	public setCityList(list){
		this.cityList = list
	}
	
	public getCity(){
		return this.cityList;
	}
	
	public createFormReset()
	{
		this.isEdit = false;
		this.createForm.controls['code'].enable();
		this.createForm.controls['mobile'].enable();
		this.errors = {};
		this.createFormMessage = false;
		this.createForm.patchValue(this.form_fields);
		this.importForm.patchValue(this.import_fields);
	}
	
	public onImportFileSelect(input) {
		  if (input.files && input.files[0]) {
		    var reader = new FileReader();
		    reader.onload = (e: any) => {
		      this.importFile = e.target.result;
		      this.importForm.value.file = e.target.result;
		    }
		    reader.readAsDataURL(input.files[0]);
		  }
		}
	
	
	//Filter form
	public changeCityManager(search:boolean = true){
		
		if(this.filterForm.value.city_manager > 0 || this.createForm.value.city_manager > 0){
			if(search){
				this.city_manager_id = this.userList[this.filterForm.value.city_manager-1]['id'];
				this.supervisorList = this.userList[this.filterForm.value.city_manager-1]['supervisor'];
			}
			else{
				this.city_manager_id = this.userList[this.createForm.value.city_manager-1]['id'];
				this.supervisorList = this.userList[this.createForm.value.city_manager-1]['supervisor'];
				
				this.filterForm.patchValue({city_manager: this.createForm.value.city_manager});
			}
		}
	}
	
	//Filter form
	public changeCityManagerForParent(){
		if(this.parentForm.value.city_manager > 0){
			
			this.city_manager_id = this.userList[this.parentForm.value.city_manager-1]['id'];
			this.supervisorList = this.userList[this.parentForm.value.city_manager-1]['supervisor'];
			
			this.parentForm.patchValue({city_manager: this.parentForm.value.city_manager});		
		}
		else{
			this.parentForm.patchValue({city_manager: 0});		
		}
	}
	
	//Filter form
	public changeCityManagerForImport(search:boolean = true){
		
		if(this.filterForm.value.city_manager > 0 || this.importForm.value.city_manager > 0){
			if(search){
				this.city_manager_id = this.userList[this.filterForm.value.city_manager-1]['id'];
				this.supervisorList = this.userList[this.filterForm.value.city_manager-1]['supervisor'];
			}
			else{
				this.city_manager_id = this.userList[this.importForm.value.city_manager-1]['id'];
				this.supervisorList = this.userList[this.importForm.value.city_manager-1]['supervisor'];
				
				this.filterForm.patchValue({city_manager: this.importForm.value.city_manager});
			}
		}
	}
	
	
	
	  
}
