import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/containers/login/login.component';
import { AuthGuard } from './auth/guards/auth.guard';
import { RandomGuard } from './auth/guards/random.guard';
import { AppGuard } from './auth/guards/app.guard';

import { MainComponent } from './_layout/main/main.component';

import { DashboardComponent } from './dashboard/dashboard.component';
import { CityListComponent } from './city/list/list.component';
import { CityWDListComponent } from './city/listWD/list.component';

import { UserListComponent } from './user/list/list.component';
import { OutletListComponent } from './outlet/list/list.component';
import { OutletActivityComponent } from './outlet/activity/activity.component';
import { SupervisorActivityComponent } from './supervisor/activity/activity.component';

import { FormDataComponent } from './form/data/data.component';
import { FormNotificationComponent } from './form/notification/notification.component';
import { FormFieldComponent } from './form/field/field.component';


import { ReportDashboardComponent } from './report/dashboard/dashboard.component';

import { ReportOutletComponent } from './report/outlet/outlet.component';
import { ReportMipComponent } from './report/mip/mip.component';
import { ReportAttandanceComponent } from './report/attandance/attandance.component';
import { ReportStockComponent } from './report/stock/stock.component';
import { ReportProductivityComponent } from './report/productivity/productivity.component';
import { ReportEccComponent } from './report/ecc/ecc.component';

import { BroadcastListComponent } from './broadcast/list/list.component';
import { TutorialListComponent } from './tutorial/list/list.component';
import { TutorialeLearningComponent } from './tutorial/elearning/list.component';

//import { AuthService } from 'src/app/auth/services/auth.service';


const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/login' },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'logout',
    loadChildren: './random/random.module#RandomModule',
    canActivate: [RandomGuard],
    canLoad: [RandomGuard]
  },
  {
      path: '',
      component: MainComponent,
      canActivate: [AppGuard],
      canLoad: [AppGuard],
      children: [
        //{ path: 'dashboard', component: ReportDashboardComponent},
        { path: 'dashboard', component: DashboardComponent},
        { path: 'city/list', component: CityListComponent},
        { path: 'city/wd/list', component: CityWDListComponent},
        { path: 'user/list', component: UserListComponent},
        { path: 'outlet/list', component: OutletListComponent},
        { path: 'outlet/activity', component: OutletActivityComponent},
        { path: 'supervisor/activity', component: SupervisorActivityComponent},
        { path: 'form/data', component: FormDataComponent},
        { path: 'form/notification', component: FormNotificationComponent},
        { path: 'form/field', component: FormFieldComponent},
        { path: 'report/dashboard', component: ReportDashboardComponent},
        { path: 'report/outlet', component: ReportOutletComponent},
        { path: 'report/mip', component: ReportMipComponent},
        { path: 'report/attandance', component: ReportAttandanceComponent},
        { path: 'report/stock', component: ReportStockComponent},
        { path: 'report/productivity', component: ReportProductivityComponent},
        { path: 'report/ecc', component: ReportEccComponent},
        { path: 'broadcast', component: BroadcastListComponent},
        { path: 'tutorial/skills', component: TutorialListComponent},
        { path: 'tutorial/elearning', component: TutorialeLearningComponent},
        
      ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule { }
