import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import {NgbDate, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';

import { ChartsModule } from 'ng2-charts';
import { DataTablesModule } from 'angular-datatables';

import { LoginComponent } from './_layout/login/login.component';
import { MainComponent } from './_layout/main/main.component';
import { DashboardComponent } from './dashboard/dashboard.component';

import { CityListComponent } from './city/list/list.component';
import { CityWDListComponent } from './city/listWD/list.component';

import { UserListComponent } from './user/list/list.component';
import { OutletListComponent } from './outlet/list/list.component';
import { OutletActivityComponent } from './outlet/activity/activity.component';
import { SupervisorActivityComponent } from './supervisor/activity/activity.component';

import { FormDataComponent } from './form/data/data.component';
import { FormNotificationComponent } from './form/notification/notification.component';

import { FormFieldComponent } from './form/field/field.component';
//import { DataTableModel } from './models/DataTable';

import { ReportDashboardComponent } from './report/dashboard/dashboard.component';

import { ReportOutletComponent } from './report/outlet/outlet.component';
import { ReportMipComponent } from './report/mip/mip.component';
import { ReportAttandanceComponent } from './report/attandance/attandance.component';
import { ReportStockComponent } from './report/stock/stock.component';
import { ReportProductivityComponent } from './report/productivity/productivity.component';
import { ReportEccComponent } from './report/ecc/ecc.component';

import { BroadcastListComponent } from './broadcast/list/list.component';
import { TutorialListComponent } from './tutorial/list/list.component';
import { TutorialeLearningComponent } from './tutorial/elearning/list.component';

import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { AppRoutingModule } from './app.routing.module';



import { DownloadService } from './download.service';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MainComponent,
    DashboardComponent,
    CityListComponent,
    CityWDListComponent,
    UserListComponent,
    OutletListComponent,
    OutletActivityComponent,
    SupervisorActivityComponent,
    FormDataComponent,
    FormNotificationComponent,
    FormFieldComponent,
    ReportDashboardComponent,
    ReportOutletComponent,
    ReportMipComponent,
    ReportAttandanceComponent,
    ReportStockComponent,
    ReportProductivityComponent,
    ReportEccComponent,
    BroadcastListComponent,
    TutorialListComponent,
    TutorialeLearningComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    DataTablesModule,
    AuthModule,
    ReactiveFormsModule,
    FormsModule,
    NgxDaterangepickerMd.forRoot(),
    ChartsModule
  ],
  providers: [
	  DownloadService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
