import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { of, Observable } from 'rxjs';
import { catchError, mapTo, tap, map } from 'rxjs/operators';
import { config } from './config';
import { Router } from '@angular/router';



@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  constructor(private http: HttpClient, private router: Router) {}

  
  public get(uri,filename) {
	  this.http.get(`${config.apiUrl}/${uri}/${filename}`,{responseType: 'blob' as 'json'}).subscribe(
        (response: any) =>{
            let dataType = response.type;
            let binaryData = [];
            binaryData.push(response);
            let downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
            if (filename)
                downloadLink.setAttribute('download', filename);
            document.body.appendChild(downloadLink);
            downloadLink.click();
        }
    )
  }
  
}

export class DownloadFileService {
    downLoadFile(data: any, type: any) {
        let blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
        let url = window.URL.createObjectURL(blob);
        let pwa = window.open(url);
        if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
            console.log( 'Please disable your Pop-up blocker and try again.');
        }
    } 
}


