export class Outlet {
	public id: string;
	city_id: string;
	name: string;
	code: string;
	area: string;
	zone: string
	address: string;
	coardinates: string;
	latitude: string;
	longitude: string;
	profile_pic: string;
	isVerified: number;
	type: string;
	status: number;
	
	public formBuilder;
	
	public cityList;
	public userList;
	public supervisorList = [];
	public fwpList:any;
	
	public filter_city_id = 0;
	
	public filterForm;
	public createForm;
	public importForm;
	public assignForm;
	
	public isEdit:boolean = false;
	public assign_outlet_id = 0;
	
	
	public importFile;
	
	public city_ids = [];
	
	public errors = {};
	public createFormMessage: any;
	
	search_fields = {
			search:'',
			city_id:0,
            is_verified:'',
            status:'' 
	}
	
	import_fields = {
			file:'',
			city_id:0
	}
	
	assign_fields = {
			city_manager:0,
			supervisor:0,
			fwp:0,
			outlet_id:0,
			start_time:0,
			end_time:0,
			meeting:'',
			activity:'',
			cycle:'',
			tse:'',
			asm:'',
			contact:'',
			community:''
	}
	
	form_fields = {
			name:'',
			code:'',
			area:'',
			zone:'',
			address:'',
			coardinates:{},
			latitude:0,
			longitude:0,
			profile_pic:'',
			city_id:0,
			isVerified:0,
			type:'',
			status:1,
	}
	
	
	
	public setFormBuilder(formBuilder){
		this.createFormMessage = false;
		
		this.formBuilder = formBuilder;
		this.filterForm = this.formBuilder.group(this.search_fields);
		
		this.createForm = this.formBuilder.group(this.form_fields);
		
		this.importForm = this.formBuilder.group(this.import_fields);
		
		this.assignForm = this.formBuilder.group(this.assign_fields);
		
		//this.formControl
	}
	
	public getFilterForm()
	{
		return this.filterForm;
	}
	
	public getFilterdata()
	{
		return this.filterForm.value;
	}
	
	public getCreateForm()
	{
		return this.createForm;
	}
	
	public getFormdata()
	{
		return this.createForm.value;
	}
	
	public getAssignForm()
	{
		return this.assignForm;
	}
	
	public getAssigndata()
	{
		return this.assignForm.value;
	}
	
	public getImportForm()
	{
		return this.importForm;
	}
	
	public getImportdata()
	{
		return this.importForm.value;
	}
	
	public setCityList(list){
		this.cityList = list
	}
	
	public getCity(){
		return this.cityList;
	}
	
	
	public setErrors(errors){
		this.errors = errors;
	}
	
	public hasError(key)
	{
		return this.errors[key];
	}
	
	public createFormReset()
	{
		this.isEdit = false;
		this.createForm.controls['code'].enable();
		this.errors = {};
		this.createFormMessage = false;
		this.createForm.patchValue(this.form_fields);
		this.importForm.patchValue(this.import_fields);
		this.assignForm.patchValue(this.assign_fields);
	}
	
	public onChecklistChange(checked, checkbox) {
		if(checked)
		{	
			//console.log(checkbox);
			this.city_ids.push(checkbox.id);
		}
		else
		{
			var index = this.city_ids.indexOf(checkbox.id);
			if (index !== -1) this.city_ids.splice(index, 1);
		}
	  }
	
	
	
	public onImportFileSelect(input) {
		  if (input.files && input.files[0]) {
		    var reader = new FileReader();
		    reader.onload = (e: any) => {
		      this.importFile = e.target.result;
		      this.importForm.value.file = e.target.result;
		    }
		    reader.readAsDataURL(input.files[0]);
		  }
		}
	
	
	
	//Assign Form
	changeCityManager(){
		if(this.assignForm.value.city_manager > 0)
			this.supervisorList = this.userList[this.assignForm.value.city_manager-1]['supervisor'];
	}
	
	
	
	  
}
