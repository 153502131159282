import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject,of, Observable } from 'rxjs';
import { HttpClient, HttpResponse, HttpParams,HttpErrorResponse} from '@angular/common/http';
import { config } from './../../config';
import { DataTablesResponse } from '../../models/common';
import { City } from './model';
import { NgForm, FormsModule, FormBuilder,FormControl  } from '@angular/forms';
import { catchError, mapTo, tap, map } from 'rxjs/operators';
import { DownloadService } from './../../download.service';

@Component({
  selector: 'app-data',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})


export class CityWDListComponent implements AfterViewInit, OnDestroy, OnInit {
	model = new City();
	dtResults: City[]
	filterForm;
	createForm;
	
	constructor(private http: HttpClient, private formBuilder: FormBuilder,public downloadService: DownloadService) {
	  this.model.setFormBuilder(formBuilder);
	  this.filterForm = this.model.getFilterForm();
	  this.createForm = this.model.getCreateForm();
	}
	
	ngOnInit() {
	  this.getList();
	  this.fetchCityList();
	}
  	
	clearSearch(){
		this.model.filterForm.patchValue(this.model.search_fields);
		this.rerender();
	}
	
	
	//Import WD Area fomr submit
	importWDArea() {
		  
		  var r = {
				  'city_id': this.model.importForm.value.city_id,
				  'data': this.model.importFile
		  };
		  
		  //console.log(r);
		  
		  this.http.post(`${config.apiUrl}/city/wd/import`,r).subscribe(resp => {
			  //console.log(resp);
			  this.model.createFormMessage = "WD Area has been imported.";
		  },(error: HttpErrorResponse)=> {
			  this.model.setErrors(error.error.error);
	      })
	  }
	
	getFormView(id){
		this.model.createFormReset();
		this.model.isEdit = true;
	    this.model.id = id;
		this.http.get(`${config.apiUrl}/city/wd/`+id).subscribe(resp => {
				this.model.createForm.controls['name'].disable();
				this.model.createForm.patchValue(resp);
		  });
	}
	
	// Create Form
	insertFormData() {
		 //this.isSubmit = true;
		 var data = {}
		 if(this.model.isEdit){
			 //console.log(this.model.createForm.value.status);
			 data = {
				  'city_id': this.createForm.value.city_id,
				  'areas': this.model.createForm.value.areas,
				  'status': Number(this.model.createForm.value.status)
			 };
			  
			 this.http.put(`${config.apiUrl}/city/wd/`+ this.model.id,data).subscribe(resp => {
				  //console.log(resp);
				  this.model.createFormMessage = "WD Area has been updated.";
				  this.model.setErrors({});
				  this.rerender();
			  },(error: HttpErrorResponse)=> {
				  this.model.setErrors(error.error.error);
		      })
		 }
		 else{
			 data = {
				  'city_id': this.createForm.value.city_id,
				  'name': this.model.createForm.value.name,
				  'areas': this.model.createForm.value.areas,
				  'status': Number(this.model.createForm.value.status)
			 };
			  
			 //console.log(data);
			 this.http.post(`${config.apiUrl}/city/wd`,data).subscribe(resp => {
				  //console.log(resp);
				  this.model.createFormMessage = "WD Area has been created.";
				  this.model.setErrors({});
				  this.rerender();
			  },(error: HttpErrorResponse)=> {
				  this.model.setErrors(error.error.error);
		      })
		 }
		 
	 }
	
	
	
	 fetchCityList(): void
	 {
		  this.http.get(`${config.apiUrl}/city/select`).subscribe(resp => {
			  this.model.setCityList(resp);
		  });
	 }  
	
	 //For Filter form
	 onSearch(data) {
		  this.rerender();
	 }
	 
	 exportData(){
		 	
	        var data = {'city_id': this.filterForm.value.city_id}	
			  
			this.http.post(`${config.apiUrl}/city/wd/export`,data).subscribe(resp => {
					//console.log("hello");
					var link = document.createElement("a");
					link.download = "Users";
					link.href = `${config.apiUrl}/report/download/`+resp['filename'];
				    link.click();
			  });
		  }
  
	 //DataTable Fetures start.............. 
	 getList(): void {
		  const that = this;
		  this.dtOptions = {
			  pagingType: 'full_numbers',
			  lengthChange: false,
			  pageLength: 10,
			  search: false,
			  serverSide: true,
			  processing: true,
			  order: [0,1,1],
			  ajax: (dataTablesParameters: any, callback) => {
			    dataTablesParameters.search = this.model.filterForm.value.search;
			    dataTablesParameters.city_id = this.model.filterForm.value.city_id;
			    
			    that.http.get<DataTablesResponse>(`${config.apiUrl}/city/wd`, {params: dataTablesParameters }).subscribe(resp => {
			          that.dtResults = resp.data;
			
			          callback({
			            recordsTotal: resp.recordsTotal,
			            recordsFiltered: resp.recordsFiltered,
			            data: []
			          })
			      });
			  },
			  //columns: [{ data: 'id' },{ data: 'name' }, { data: 'code' },{ data: 'area' },{ data: 'zone' },{ data: 'address' }, { data: 'coardinates.latitude' },{ data: 'coardinates.longitude' },{ data: 'status'}],
			  columnDefs: [{orderable: false, targets: [0,1,2,3]}],
		  };
	  }
	 
	  @ViewChild(DataTableDirective, {static: false})
	  dtElement: DataTableDirective;
	  dtOptions: DataTables.Settings = {};
	  dtTrigger: Subject<any> = new Subject();
	  
	  ngAfterViewInit(): void { this.dtTrigger.next();}
	
	  ngOnDestroy(): void { this.dtTrigger.unsubscribe();}
	
	  rerender(): void { this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {dtInstance.draw();});}
	  //DataTable Fetures End.............. 
}
