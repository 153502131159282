export class City {
	public id: string;
	name: string;
	status: any;
	
	public formBuilder;
	
	public filterForm;
	public createForm;
	public createWDForm;
	
	
	public isEdit = false;
	
	public errors = {};
	public createFormMessage: any;
	
	search_fields = {
			search:''
	}
	form_fields = {
			name:'',
			areas:'',
			status:1
	}
	
	
	
	
	
	public setFormBuilder(formBuilder){
		this.createFormMessage = false;
		
		this.formBuilder = formBuilder;
		this.filterForm = this.formBuilder.group(this.search_fields);
		
		this.createForm = this.formBuilder.group(this.form_fields);
		
	}
	
	public getFilterForm()
	{
		return this.filterForm;
	}
	
	public getFilterdata()
	{
		return this.filterForm.value;
	}
	
	public getCreateForm()
	{
		return this.createForm;
	}
	
	public getFormdata()
	{
		return this.createForm.value;
	}

	public setErrors(errors){
		this.errors = errors;
	}
	
	public hasError(key)
	{
		return this.errors[key];
	}
	
	
	public createFormReset()
	{
		this.isEdit = false;
		this.createForm.controls['name'].enable();
		this.errors = {};
		this.createFormMessage = false;
		this.createForm.patchValue(this.form_fields);
		
	}	  
}
