import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject,of, Observable } from 'rxjs';
import { HttpClient, HttpResponse, HttpParams,HttpErrorResponse} from '@angular/common/http';
import { config } from './../config';
import { DataTablesResponse } from './../models/common';
import { Attandance } from './model';
import { NgForm, FormsModule, FormBuilder,FormControl  } from '@angular/forms';
import { catchError, mapTo, tap, map } from 'rxjs/operators';
import * as moment from 'moment';


import { Chart,ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import 'chartjs-plugin-labels';
import { Label } from 'ng2-charts';


@Component({
  selector: 'app-data',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})


export class DashboardComponent implements  OnInit {
	model = new Attandance();
	dtResults: Attandance[]
	filterForm;
	createForm;
	
	total_working_days = 0;
	total_man_days = 0;
	total_outlet = 0;
	unique_outlet = 0;
	total_fwp = 0;
    total_ecc = 0;
    total_cc = 0;
	
	
	total_brand = 0;
	total_offerbrand = 0;
	total_gender = 0;
	total_ageSplit = 0;
	total_area = 0;
	total_achievement = 0;
	achievement = []
	
	
	dateLabel = 'Today';
	tempDateLabel;
	fromDate;
	toDate;
	
		//Calender View start
		  selected: any;
		  alwaysShowCalendars: boolean;
		  showRangeLabelOnInput: boolean;
		  keepCalendarOpeningWithRange: boolean;
		  maxDate: moment.Moment;
		  minDate: moment.Moment;
		  invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];
		  ranges: any = {
		    Today: [moment(), moment()],
		    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
		    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
		    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
		    'This Month': [moment().startOf('month'), moment().endOf('month')],
		    'Last Month': [
		      moment()
		        .subtract(1, 'month')
		        .startOf('month'),
		      moment()
		        .subtract(1, 'month')
		        .endOf('month')
		    ]
		  };

		  isInvalidDate = (m: moment.Moment) =>  {
		    return this.invalidDates.some(d => d.isSame(m, 'day') )
		  }
		  rangeClicked(range) {
			  this.tempDateLabel = range.label;
			  //console.log('[rangeClicked] range is : ', range);
		  }
		  datesUpdated(range) {
			  if(range['startDate'] != null){
				  this.fromDate = range['startDate'].format("YYYY-MM-DD");
				  this.toDate = range['endDate'].format("YYYY-MM-DD");
				  
			  }
			  this.dateLabel = this.tempDateLabel;
			  //console.log('[datesUpdated] range is : ', range);
			 
		  }
		  setDate(d){
			  var range = {
				  'endDate':this.ranges[d][1],
				  'startDate':this.ranges[d][0]
			  }
			  
			  this.rangeClicked({'label':d,'dates':this.ranges[d]});
			  this.datesUpdated(range);
		  }
		//Calender View End
		 
		   getRandomColor() {
			   let graphColors = [];
			   for (var j = 0; j < 100; j++) { 
				  var letters = '0123456789ABCDEF';
				  var color = '#';
				  for (var i = 0; i < 6; i++) {
				    color += letters[Math.floor(Math.random() * 16)];
				  }
				  graphColors.push(color);
				  //return color;
			   }
			   return graphColors;
			}
		   
		   
		 chartColors = ['#f4cccc','#ff9900','#20124d','#6d9eeb','#f1c232','#674ea7','#bf9000','#cc0000','#1a22e1','#2bc549','#629d58','#e66adf','#b71b89','#ab6c26'];
		 chartLegendLabel = {fontColor: '#676a6c',fontStyle: 'bold'};
		 
		 barChartOptions:ChartOptions = {
				    responsive: true,
				    legend: {
				          display:false,
					      position: 'top',
					      labels: this.chartLegendLabel
					    },
				    scales: { xAxes: [{}], yAxes: [{}] },
				    layout: {
				        padding: {
				            left: 0,
				            right: 0,
				            top: 30,
				            bottom: 0
				        }
				    },
				    plugins: {
				    	  labels: {
				    		  position:'border',
				    		  fontColor: '#676a6c',
				    		  fontSize: 12,
				    		  fontStyle:'bold',
				    		  overlap: true,
				    		  outsidePadding: 14,
				    		  textMargin: 2,
				    		  arc: true,
				    		  showActualPercentages: true,
				    		  render: function (args) {
				    			    //console.log(args);
				    			    var sum = args.dataset.data.reduce((acc, cur) => acc + Number(cur), 0)
				    			    let percentage;
				    			    if(sum > 0)
				    			    		percentage = Math.round((args.value / sum) * 100) + '%';
				    			    else 
				    			    		percentage = '0%';
				    			    return args.value + " / "+percentage;
				    			}
				    		  },
				      datalabels: {
				    	display:false,
				        anchor: 'center',
				        align: 'center',
				        color: '#d95450',
				        borderColor:'#d95450',
				        font:{weight:"bold"},
				        formatter: (value, ctx) => {
							  var sum = this.genderChartData.reduce((acc, cur) => acc + Number(cur), 0)
						        
							  let percentage = Math.round((value / sum) * 100) + '%';
				        	  	  const label = ctx.chart.data.labels[ctx.dataIndex];
				        	  	  return value + " / "+percentage;
					      }
				      }
				    }
				  }; 
		 
		  pieChartOptions: ChartOptions = {
				    responsive: true,
				    legend: {
			          display:true,
				      position: 'top',
				      labels: this.chartLegendLabel
				    },
				    plugins: {
				    	  labels: {
			    			render: function (args) {
			    				var sum = args.dataset.data.reduce((acc, cur) => acc + Number(cur), 0)
			    				
							let percentage = Math.round((args.value / sum) * 100) + '%';
			    			    return args.label + " : "+args.value + " / "+percentage;
			    			},
			    			position:'outside',
			    			fontColor: '#676a6c',
				    		fontSize: 12,
				    		fontStyle:'bold',
				    		overlap: true,
				    		outsidePadding: 0,
				    		textMargin: 5,
			    			arc: false
			    		  },
				      datalabels: {
				    	    display:false,
				    	    color: '#FFFFFF',
				        formatter: (value, ctx) => {
				        		//return "";
				         	var sum = this.genderChartData.reduce((acc, cur) => acc + Number(cur), 0)
					        
				         	let percentage = Math.round((value / sum) * 100) + '%';
			        	  		const label = ctx.chart.data.labels[ctx.dataIndex];
			        	  		return label + " - " + value + "/"+percentage;
				        },
				      },
				    }
				  };
		 
		 
		 
		  
		  //Age split bar chart
		  public ageChartOptions: ChartOptions = this.barChartOptions;
		  public ageChartLabels: Label[] = ['18-24','25-29','30-34','35-44','>44'];
		  public ageChartType: ChartType = 'bar';
		  public ageChartLegend = true;
		  public ageChartPlugin = [pluginDataLabels];

		  public ageChartData: ChartDataSets[] = [{ data: [0,0,0,0,0] , label:'TOTAL'}];
		  public ageChartColors = [{backgroundColor:this.chartColors}];
		  

		  
		  //brand bar chart group
		  public brandChartOptions: ChartOptions = this.barChartOptions;
		  public brandChartLabels: any[] = [];
		  public brandChartType: ChartType = 'bar';
		  public brandChartLegend = true;
		  public brandChartPlugins = [];
		  public brandChartData: ChartDataSets[] = [{ data: [], label: 'TOTAL' }];
		  
		  public brandChartColors = [{backgroundColor:this.chartColors}];
		
		  //Offer brand bar chart group
		  public offerBrandChartOptions: ChartOptions = this.barChartOptions;
		  public offerBrandChartLabels: any[] = [];
		  public offerBrandChartType: ChartType = 'bar';
		  public offerBrandChartLegend = true;
		  public offerBrandChartPlugins = [];

		  public offerBrandChartData: ChartDataSets[] = [
		    { data: [], label: 'TOTAL' },
		  ];
		  public offerBrandChartColors = [{backgroundColor:this.chartColors}];

		  
		  //Gender Pie Chart 
		  public genderChartOptions: ChartOptions = this.pieChartOptions;
		  public genderChartLabels: any[] = ['Male','Female'];
		  public genderChartData: any[] = [0, 0];
		  public genderChartType: ChartType = 'pie';
		  public genderChartLegend = true;
		  public genderChartPlugins = [];
		  public genderChartColors = [{backgroundColor:this.chartColors}];
		  
		  
		  //Area Pie Chart 
		  public areaChartOptions: ChartOptions = this.barChartOptions;
		  public areaChartLabels: any[] = [];
		  public areaChartType: ChartType = 'bar';
		  public areaChartLegend = true;
		  public areaChartPlugins = [pluginDataLabels];
		  public areaChartData: ChartDataSets[] = [{ data: [], label: 'TOTAL' }];
		  public areaChartColors = [{backgroundColor:this.getRandomColor()}];
		 
		  
		  
		  //achievment bar chart
		  public achievementChartOptions: ChartOptions = this.barChartOptions;
		  public achievementChartLabels: Label[] = ['CC','ECC'];
		  public achievementChartType: ChartType = 'bar';
		  public achievementChartLegend = true;
		  public achievementChartPlugins = [pluginDataLabels];

		  //public achievementChartData: ChartDataSets[] = [{ data: [0,0], label: 'TOTAL' }];
		  public achievementChartData = [];
		  public achievementChartColors = [{backgroundColor:this.chartColors}];

		  
		  
				  
	constructor(private http: HttpClient, private formBuilder: FormBuilder) {
	  this.model.setFormBuilder(formBuilder);
	  this.filterForm = this.model.getFilterForm();
	  this.createForm = this.model.getCreateForm();
	  
	    this.fromDate = moment().format("YYYY-MM-DD");
		this.toDate = moment().format("YYYY-MM-DD");
		
	    this.alwaysShowCalendars = true;
	    this.keepCalendarOpeningWithRange = true;
	    this.showRangeLabelOnInput = true;
	    this.selected = this.ranges['Today'];
	    this.tempDateLabel = "Today";  
	}
	
	ngOnInit() {
		this.fetchCityList();
		this.fetchBrandList();
		this.fetchOfferBrandList();
		this.fetchSupervisorList();
		this.fetchOutletList();
		this.onSearch({});
		//console.log(this.chartColors);
	}
  	
	
	 onSearch(data)
	 {
		 $('#loader').show();
		 var r = {
				  'fromDate': this.fromDate,
				  'toDate': this.toDate,
				  'city_id': this.filterForm.value.city_id,
				  'area': this.filterForm.value.area,
				  'outlet_id': this.filterForm.value.outlet_id,
				  'program': this.filterForm.value.program,
				  'brand_id': this.filterForm.value.brand_id,
				  'offer_brand_id': this.filterForm.value.offer_brand_id,
				  'activity': this.filterForm.value.activity,
				  'supervisor_id': this.filterForm.value.supervisor,
				  'fwp_id': this.filterForm.value.fwp,
   		  };
		 
		 this.http.post(`${config.apiUrl}/web/graph`,r).subscribe(resp => {
			   //console.log(resp);
			   //this.pieChartData = [100, 200];
			   
			   var brand:any = resp['brand'];
			   this.brandChartData[0].data = brand['value'];
			   this.brandChartLabels = brand['label'];
			   this.total_brand = brand['total'];
			   
			   var offerBrand:any = resp['offerBrand'];
			   this.offerBrandChartData[0].data = offerBrand['value'];
			   this.offerBrandChartLabels = offerBrand['label'];
			   this.offerBrandChartColors = [{backgroundColor:offerBrand['color']}];
			   this.total_offerbrand = offerBrand['total'];
			   
			   
			   var area:any = resp['area'];
			   this.areaChartData[0].data = area['value'];
			   this.areaChartLabels = area['label'];
			   this.total_area = area['total'];
			   
			   var achievement:any = resp['achievement']
			   this.achievement = achievement
			   //this.achievementChartData[0].data = [achievement['cc'],achievement['ecc']]
			   //this.achievementChartData = [achievement['cc'],achievement['ecc']]
			   //this.total_achievement = achievement['total'];
			   
			   var ageSplit:any = resp['ageSplit']
			   this.ageChartData[0].data = [ageSplit['18-24'],ageSplit['25-29'],ageSplit['30-34'],ageSplit['35-44'],ageSplit['>44'],]
			   this.total_ageSplit = ageSplit['total'];
			   
			   var gender:any = resp['gender'];
			   this.genderChartLabels = ['MALE','FEMALE'];
			   this.genderChartData = [gender['MALE'],gender['FEMALE']];
			   this.total_gender = gender['total'];
			   
			   
			   this.http.post(`${config.apiUrl}/web/graph/summary`,r).subscribe(resp => {
				   //console.log(resp);
				   //this.pieChartData = [100, 200];
				   var summary = resp; 
				   
				   this.total_working_days = summary['total_working_days'];
				   this.total_man_days = summary['total_man_days'];
				   this.total_outlet = summary['total_outlet'];
				   this.unique_outlet = summary['unique_outlet'];
				   this.total_fwp = summary['total_fwp'];
				   
				   this.achievementChartData = [summary['total_cc'],this.achievement['ecc']]
				   this.total_achievement = summary['total_cc'] + this.achievement['ecc']
                   this.total_ecc = this.achievement['ecc']
                   this.total_cc = summary['total_cc']
				   
				   //console.log(this.achievementChartData);
				   $('#loader').hide();
			  },(error: HttpErrorResponse)=> {
				  $('#loader').hide();
		      });
			   
		  },(error: HttpErrorResponse)=> {
			  $('#loader').hide();
	      });
		 
		 
		 
	 }
	
	 fetchUserList(): void {
		  this.http.get(`${config.apiUrl}/user/select`).subscribe(resp => {
			  this.model.userList = resp;
		  });
	 }
	 
	 fetchSupervisorList(): void {
		  var city = this.filterForm.value.city_id;
		  this.http.get(`${config.apiUrl}/user/selectbycity/`+city).subscribe(resp => {
			  this.model.supervisorList = resp;
		  });
	 }
	 
	 fetchOutletList(): void
	 {    $('#loader').show();  
		  var city = this.filterForm.value.city_id;
		  var area = this.filterForm.value.area;
		  this.http.get(`${config.apiUrl}/outlet/select/`+city+"/"+area).subscribe(resp => {
			  //console.log(resp);
			  this.model.outletList = resp;
              setTimeout(()=>{                           // <<<---using ()=> syntax
                $('#loader').hide();
              }, 10000);
		  });
	 }
	 
	 
	 changeWDArea(){
		 //this.fetchOutletList();
		 
		 if(this.filterForm.value.wdarea != 'all')
		 {
			var wdarea = this.filterForm.value.wdarea;
			
			let obj = this.model.wdList.find((o, i) => {
			    if (o.id === wdarea) {
			        return true; // stop searching
			    }
			});
			this.model.areaList = obj['areas'];
			//console.log(this.model.areaList);
			
		 }
	 }
	 
	 
	 changeArea(){
		 this.fetchOutletList();
	 }
	 
	//Assign Form
	changeSupervisor(){
		if(this.filterForm.value.supervisor != 'all')
		{
			var supervisor = this.filterForm.value.supervisor;
			this.http.get(`${config.apiUrl}/user/select/`+supervisor).subscribe(resp => {
				  this.model.fwpList = resp;
			  });
		}
	}
	
	
		
	 //Change City and Fetch WD Area List	
	 changeCity(): void
	 {
		  var id = this.filterForm.value.city_id;
		  this.http.get(`${config.apiUrl}/city/wd/select/`+id).subscribe(resp => {
			  //console.log(resp);
			  this.model.setWDList(resp);
		  });
		  this.fetchOutletList();
		  this.fetchSupervisorList();
	 }
	 
	 fetchCityList(): void
	 {
		  this.http.get(`${config.apiUrl}/city/select`).subscribe(resp => {
			  //console.log(resp);
			  this.model.setCityList(resp);
		  });
	 }
	 fetchBrandList(): void
	 {
		  this.http.get(`${config.apiUrl}/brand/select`).subscribe(resp => {
			  this.model.setBrandList(resp);
		  });
	 }
	 fetchOfferBrandList(): void
	 {
		  this.http.get(`${config.apiUrl}/offerBrand/select`).subscribe(resp => {
			  this.model.setOfferBrandList(resp);
		  });
	 }
	
	 
	
}
