import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject,of, Observable } from 'rxjs';
import { HttpClient, HttpResponse, HttpParams,HttpErrorResponse} from '@angular/common/http';
import { config } from './../../config';
import { DataTablesResponse } from '../../models/common';
import { Outlet,Form } from './../model';
import { NgForm, FormsModule, FormBuilder,FormControl  } from '@angular/forms';
import { catchError, mapTo, tap, map } from 'rxjs/operators';
import * as moment from 'moment';
import { DownloadFileService } from './../../download.service';

@Component({
  selector: 'app-data',
  templateUrl: './data.component.html',
  styleUrls: ['./data.component.css']
})




export class FormDataComponent implements AfterViewInit, OnDestroy, OnInit {
  model = new Form();	
  outlets: Outlet[];	
  miForms: Form[];
  downf = new DownloadFileService();
  
  filterForm;
  filterData;
  createForm;
  selectLists; 
  city_id = 0;
  formView;
  activity_title = "Outlet Checkin Activity";
  formFieldsStep1;
  formFieldsStep2;
  formData = {};
  
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  
  dateLabel = 'Today';
  tempDateLabel;
  fromDate;
  toDate;
	
//Calender View start
  selected: any;
  alwaysShowCalendars: boolean;
  showRangeLabelOnInput: boolean;
  keepCalendarOpeningWithRange: boolean;
  maxDate: moment.Moment;
  minDate: moment.Moment;
  invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];
  ranges: any = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [
      moment()
        .subtract(1, 'month')
        .startOf('month'),
      moment()
        .subtract(1, 'month')
        .endOf('month')
    ]
  };

  isInvalidDate = (m: moment.Moment) =>  {
    return this.invalidDates.some(d => d.isSame(m, 'day') )
  }
  rangeClicked(range) {
	  this.tempDateLabel = range.label;
	  //console.log('[rangeClicked] range is : ', range);
  }
  datesUpdated(range) {
	  if(range['startDate'] != null){
		  this.fromDate = range['startDate'].format("YYYY-MM-DD");
		  this.toDate = range['endDate'].format("YYYY-MM-DD");
		  
	  }
	  this.dateLabel = this.tempDateLabel;
	  //console.log('[datesUpdated] range is : ', range);
	 
  }
  setDate(d){
	  var range = {
		  'endDate':this.ranges[d][1],
		  'startDate':this.ranges[d][0]
	  }
	  
	  this.rangeClicked({'label':d,'dates':this.ranges[d]});
	  this.datesUpdated(range);
  }
  //Calender View End
	
  constructor(private http: HttpClient, private formBuilder: FormBuilder) {
	  this.filterForm = this.formBuilder.group({
		  	search:'',
			fwp_code:'',
			outlet_code:'',
			city_id:0
	      });
	    
	    this.filterData = this.filterForm.value;
	    this.model.setFormBuilder(formBuilder);
	    //this.createForm = this.model.getCreateForm();
	    
	    this.fromDate = moment().format("YYYY-MM-DD");
		this.toDate = moment().format("YYYY-MM-DD");
		
	    this.alwaysShowCalendars = true;
	    this.keepCalendarOpeningWithRange = true;
	    this.showRangeLabelOnInput = true;
	    this.selected = this.ranges['Today'];
	    this.tempDateLabel = "Today";  
  }

  ngOnInit() {
	  this.getSelectCities();
	  this.getList();
  }
  
  onSearch(data) {
	this.filterData = this.filterForm.value;
    this.rerender();
  }
  
  clearSearch(){
		this.filterForm.patchValue(this.model.search_fields);
		this.onSearch(this.model.search_fields);

	}
  
  downloadPMIAttendenceReport(filename) {
       return this.http.get(`${config.apiUrl}/report/download/`+filename, {responseType: 'blob'}).subscribe(response => this.downf.downLoadFile(response, " application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"))
  }
  exportData(){
	  $('#loader').show();
	  var r = {
			  'fromDate': this.fromDate,
			  'toDate': this.toDate,
			  'city_id': this.city_id,
			  'fwp_code': this.filterData.fwp_code,
     		  'outlet_code': this.filterData.outlet_code
            
		  };
      
	  this.http.post(`${config.apiUrl}/form/export`,r, {responseType: 'blob'}).subscribe(resp => {
		    //console.log("hello");
            // commented for VAPT issue
		    $('#loader').hide();
            this.downf.downLoadFile(resp, " application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
		  	//~ var link = document.createElement("a");
		    //~ link.download = "Form Data";
		    //~ link.href = `${config.apiUrl}/report/download/`+resp['filename'];
		    //~ link.click();
	  },(error: HttpErrorResponse)=> {
		  $('#loader').hide();
      });
  }
  

  
  getSelectCities(): void
  {
	  this.http.get(`${config.apiUrl}/city/select`).subscribe(resp => {
		  this.selectLists = resp;
	  });
  }
  
  changeCity(filter){
	  
	  if(filter)this.filterData = this.filterForm.value;
	  
	  if(this.filterData.city_id > 0){
		  this.city_id = this.selectLists[this.filterData.city_id - 1]['id'];
	  }
	  else
	  {
		  this.city_id = 0
	  }
	  
	  
	  this.rerender();
  }
  
  public getList(): void {
	    const that = this;
	    this.dtOptions = {
	      pagingType: 'full_numbers',
	      lengthChange: false,
	      pageLength: 10,
	      search: false,
	      serverSide: true,
	      processing: true,
	      order: [0,1,1],
	      ajax: (dataTablesParameters: any, callback) => {
	        dataTablesParameters.fwp_code = this.filterData.fwp_code;
	        dataTablesParameters.outlet_code = this.filterData.outlet_code;
	        dataTablesParameters.city_id = this.city_id;
	        dataTablesParameters.fromDate = this.fromDate;
	        dataTablesParameters.toDate = this.toDate;
	        
	        that.http.get<DataTablesResponse>(`${config.apiUrl}/form`, {params: dataTablesParameters }).subscribe(resp => {
	              that.miForms = resp.data;

	              callback({
	                recordsTotal: resp.recordsTotal,
	                recordsFiltered: resp.recordsFiltered,
	                data: []
	              })
	          });
	      },
	      //columns: [{ data: 'id' },{ data: 'name' }, { data: 'code' },{ data: 'area' },{ data: 'zone' },{ data: 'address' }, { data: 'coardinates.latitude' },{ data: 'coardinates.longitude' },{ data: 'status'}],
	      columnDefs: [{orderable: false, targets: [0,1,2,3,4,5,6]}],
	    };
	  }

	  ngAfterViewInit(): void {
	    this.dtTrigger.next();
	  }

	  ngOnDestroy(): void {
	    this.dtTrigger.unsubscribe();

	  }

	  rerender(): void {
	      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
	         dtInstance.draw();
	         //this.dtTrigger.next();
	      });
	  }
	  
	  insertFormData(){
		  var data = this.model.createForm.value;
		  console.log(data);
		  
		  this.http.put(`${config.apiUrl}/form/`+ this.model.id,data).subscribe(resp => {
			  //console.log(resp);
			  this.model.createFormMessage = "Form data has been updated.";
			  this.model.setErrors({});
			  this.rerender();
		  },(error: HttpErrorResponse)=> {
			  this.model.setErrors(error.error.error);
	      })
	      
	      
	  }
	  
	  getFormFields(id,city_id){
		  this.model.id = id;
		  this.model.createFormReset();
		  var form_fields = {}
		  var resp = [];
		  var ignoreFields = ["user_id", "city_id", "outlet_id", "coardinates","date"];
		 
		  
		  this.http.get(`${config.apiUrl}/form/`+id).subscribe(resp => {
			   this.formView = resp;
			   var length =  Object.keys(resp).length; 
			   
			   for (var index = 0; index < length; index++) { 
				   
				   let name = resp[index].name;
				   
				   if(ignoreFields.indexOf(name) == -1){
					   //if(name != 'user_id' || name != 'city_id' || name != 'outlet_id' || name != 'coardinates' || name != 'date'){
					   this.model.form_fields[resp[index].name]= resp[index].value;
					   this.formData[resp[index].name]= resp[index].value;
					   
					   if(resp[index].name == 'brands' || resp[index].name == 'variant' ){
						   this.model.form_fields[resp[index].name]= resp[index].idValue;
						   this.formData[resp[index].name]= resp[index].idValue;
					   }
				   }
				   
				} 
			  //console.log(this.model.form_fields);
			  this.model.createForm = this.formBuilder.group(this.model.form_fields);
			  
			  this.http.get(`${config.apiUrl}/form/field/city/`+city_id).subscribe(resp => {
				  this.formFieldsStep1 = resp['step1'];
				  this.formFieldsStep2 = resp['step2'];
			  });
			   
		  });
		  
		 
		  
		  
		 
      }
	  
	  getFormView(id){
		  this.activity_title = "Form View";
		  this.http.get(`${config.apiUrl}/form/`+id).subscribe(resp => {
			  this.formView = resp;
		  });
      }
	  
	  
}
