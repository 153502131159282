import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject,of, Observable } from 'rxjs';
import { HttpClient, HttpResponse, HttpParams,HttpErrorResponse} from '@angular/common/http';
import { config } from './../../config';
import { DataTablesResponse } from '../../models/common';
import { Outlet,Form } from './../model';
import { NgForm, FormsModule, FormBuilder } from '@angular/forms';
import { catchError, mapTo, tap, map } from 'rxjs/operators';


@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})

export class FormNotificationComponent implements AfterViewInit, OnDestroy, OnInit {
	
  outlets: Outlet[];	
  miForms: Form[]
  filterForm;
  filterData;
  selectLists; 
  city_id = 0;
  formView;
  activity_title = "Outlet Checkin Activity";
  
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  
	
  constructor(private http: HttpClient, private formBuilder: FormBuilder) {
	  this.filterForm = this.formBuilder.group({
	        search: '',
	        city_id:0,
	      });
	    
	    this.filterData = this.filterForm.value;
  }

  ngOnInit() {
	  this.getSelectCities();
	  this.getList();
  }
  
  onSearch(data) {
	this.filterData = this.filterForm.value;
    this.rerender();
  }

  
  getSelectCities(): void
  {
	  this.http.get(`${config.apiUrl}/city/select`).subscribe(resp => {
		  this.selectLists = resp;
	  });
  }
  
  changeCity(filter){
	  console.log();
	  if(filter)this.filterData = this.filterForm.value;
	  
	  if(this.filterData.city_id > 0){
		  this.city_id = this.selectLists[this.filterData.city_id - 1]['id'];
	  }
	  else
	  {
		  this.city_id = 0
	  }
	  
	  
	  this.rerender();
  }
  
  public getList(): void {
	    const that = this;
	    this.dtOptions = {
	      pagingType: 'full_numbers',
	      lengthChange: false,
	      pageLength: 10,
	      search: false,
	      serverSide: true,
	      processing: true,
	      order: [0,1,1],
	      ajax: (dataTablesParameters: any, callback) => {
	        dataTablesParameters.search = this.filterData.search;
	        dataTablesParameters.city_id = this.city_id;
	        
	        that.http.get<DataTablesResponse>(`${config.apiUrl}/form/notification`, {params: dataTablesParameters }).subscribe(resp => {
	              that.miForms = resp.data;

	              callback({
	                recordsTotal: resp.recordsTotal,
	                recordsFiltered: resp.recordsFiltered,
	                data: []
	              })
	          });
	      },
	      //columns: [{ data: 'id' },{ data: 'name' }, { data: 'code' },{ data: 'area' },{ data: 'zone' },{ data: 'address' }, { data: 'coardinates.latitude' },{ data: 'coardinates.longitude' },{ data: 'status'}],
	      columnDefs: [{orderable: false, targets: [0,1,2,3,4,5,6]}],
	    };
	  }

	  ngAfterViewInit(): void {
	    this.dtTrigger.next();
	  }

	  ngOnDestroy(): void {
	    this.dtTrigger.unsubscribe();

	  }

	  rerender(): void {
	      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
	         dtInstance.draw();
	         //this.dtTrigger.next();
	      });
	  }
	  
	  getFormView(id){
		  this.activity_title = "Form View";
		  this.http.get(`${config.apiUrl}/form/notification/`+id).subscribe(resp => {
			  this.formView = resp;
		  });
      }
}
