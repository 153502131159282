export class City {
	public id: string;
	name: string;
	status: any;
	
	public importFile;

	public langList;
	
	public formBuilder;
	
	public filterForm;
	public createForm;
	
	public userList;
	public supervisorList = [];
	public fwpList:any;
	
	public isEdit = false;
	
	public errors = {};
	public createFormMessage: any;
	
	search_fields = {
			search:''
	}
	form_fields = {
			lang_id:'',
			role:0,
			title:'',
			type:'',
			url:'',
			file:'',
			status:1
	}
	
	
	public setFormBuilder(formBuilder){
		this.createFormMessage = false;
		
		this.formBuilder = formBuilder;
		this.filterForm = this.formBuilder.group(this.search_fields);
		
		this.createForm = this.formBuilder.group(this.form_fields);
	}
	
	public getFilterForm()
	{
		return this.filterForm;
	}
	
	public getFilterdata()
	{
		return this.filterForm.value;
	}
	
	public getCreateForm()
	{
		return this.createForm;
	}
	
	public getFormdata()
	{
		return this.createForm.value;
	}
	
	
	
	
	public setErrors(errors){
		this.errors = errors;
	}
	
	public hasError(key)
	{
		return this.errors[key];
	}
	
	public createFormReset()
	{
		this.isEdit = false;
		//this.createForm.controls['name'].enable();
		this.errors = {};
		this.createFormMessage = false;
		this.createForm.patchValue(this.form_fields);
		
	}	 
	
	//Assign Form
	changeCityManager(){
		if(this.createForm.value.city_manager > 0)
			this.supervisorList = this.userList[this.createForm.value.city_manager-1]['supervisor'];
	}
	
	
	
	public onImportFileSelect(input) {
		  //console.log(input);
		  if (input.files && input.files[0]) {
		    var reader = new FileReader();
		    reader.onload = (e: any) => {
		      this.importFile = e.target.result;
		      this.createForm.value.file = e.target.result;
		    }
		    reader.readAsDataURL(input.files[0]);
		  }
		}
}
