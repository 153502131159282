import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { of, Observable } from 'rxjs';
import { catchError, mapTo, tap, map } from 'rxjs/operators';
import { config } from './../../config';
import { Tokens } from '../models/tokens';
import { Router } from '@angular/router';


export const InterceptorSkipHeader = new HttpHeaders({
  'X-Skip-Interceptor': ''
});

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly JWT_TOKEN = 'JWT_TOKEN';
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
  private readonly ROLE = 'ROLE';
  private loggedUser: string;
  private token: Tokens;
  private roleId;

  constructor(private http: HttpClient, private router: Router) {}

  login(user: { mobile: string, password: string }): Observable<boolean> {

    return this.http.post<any>(`${config.apiUrl}/login`, user, { headers : InterceptorSkipHeader })
      .pipe(map((data: any) => {
            if(data && data.token.access) {
            		  //console.log(data);
            		  this.roleId = Number(data.profile.role);
            		  if(this.roleId < 3){
            			  console.log("failed...");
            			  return false;
            		  }
                      let t = {jwt : data.token.access, refreshToken: data.token.refresh}
                      this.doLoginUser(user.mobile, t)
                      return true
                    }
            return false;
          }),
          catchError((error: any) => {
            return of(false);
        }));
  }

  logout() {
    return this.http.delete<any>(`${config.apiUrl}/logout`).pipe(
      tap(() => this.doLogoutUser()),
      mapTo(true),
      catchError(error => {
        //this.router.navigate(['/login']);
        //alert(error.error);
        return of(false);
      }));
  }

  isLoggedIn() {
    return !!this.getJwtToken();
  }
  
  

  refreshToken() {
    return this.http.post<any>(`${config.apiUrl}/refresh`, {
      'refreshToken': this.getRefreshToken()
    })
     .pipe(map((data:any) =>{
        if(data && data.token.access){
            let t = {jwt : data.token.access}
            this.storeJwtToken(t.jwt);
            return true
        } else {
            //this.doLogoutUser();
            return false;
        }
      }));
  }
  
  getCurrentRole(){
	  return Number(sessionStorage.getItem(this.ROLE));
  }

  getJwtToken() {
	  return sessionStorage.getItem(this.JWT_TOKEN);
  }

  private doLoginUser(username: string, tokens: Tokens) {
    this.loggedUser = username;
    this.storeTokens(tokens);
  }

  doLogoutUser() {
    this.loggedUser = null;
    this.removeTokens();
  }

  getRefreshToken() {
    return sessionStorage.getItem(this.REFRESH_TOKEN);
  }

  private storeJwtToken(jwt: string) {
	  sessionStorage.setItem(this.JWT_TOKEN, jwt);
	  sessionStorage.setItem(this.ROLE, this.roleId);
  }

  private storeTokens(tokens: Tokens) {
	  sessionStorage.setItem(this.JWT_TOKEN, tokens.jwt);
	  sessionStorage.setItem(this.REFRESH_TOKEN, tokens.refreshToken);
	  
	  sessionStorage.setItem(this.ROLE, this.roleId);
  }

  private removeTokens() {
	  sessionStorage.removeItem(this.JWT_TOKEN);
	  sessionStorage.removeItem(this.REFRESH_TOKEN);
	  sessionStorage.removeItem(this.ROLE);
  }
}
