export class Attandance {
	public id: string;
	date: string;
	
	public formBuilder;
	
	public filterForm;
	public createForm;
	
	public errors = {};
	public createFormMessage: any;
	
	
	public cityList;
	public userList;
	public supervisorList:any = [];
	public fwpList:any;
	public outletList:any = [];
	
	public brandList;
	public offerBrandList;
	public summary:any;
	
	public wdList:any = []
	public areaList:any = [];
	
	
	search_fields = {
			search:'',
			city_id:'all',
			outlet_id:'all',
			wdarea:'all',
			area:'all',
			program:'all',
			brand_id:'all',
			offer_brand_id:'all',
			city_manager:0,
			supervisor:'all',
			fwp:'all',
			activity:'all'
	}
	form_fields = {
			date:"2019-10-01"
	}
	
	public setFormBuilder(formBuilder){
		this.createFormMessage = false;
		
		this.formBuilder = formBuilder;
		this.filterForm = this.formBuilder.group(this.search_fields);
		
		this.createForm = this.formBuilder.group(this.form_fields);
	}
	
	public getFilterForm()
	{
		return this.filterForm;
	}
	
	public getFilterdata()
	{
		return this.filterForm.value;
	}
	
	public getCreateForm()
	{
		return this.createForm;
	}
	
	public getFormdata()
	{
		return this.createForm.value;
	}
	
	
	
	public setCityList(list){
		this.cityList = list
	}
	
	public setWDList(list){
		this.wdList = list
	
	}
	
	
	public setBrandList(list){
		this.brandList = list
	}
	
	public setOfferBrandList(list){
		this.offerBrandList = list
	}
	
	
	public setErrors(errors){
		this.errors = errors;
	}
	
	public hasError(key)
	{
		return this.errors[key];
	}
	
	public createFormReset()
	{
		this.errors = {};
		this.createFormMessage = false;
		this.createForm.patchValue(this.form_fields);
		
	}	  
	
	//Assign Form
	changeCityManager(){
		if(this.filterForm.value.city_manager > 0)
			this.supervisorList = this.userList[this.filterForm.value.city_manager-1]['supervisor'];
	}
	
	
}
