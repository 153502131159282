export class City {
	public id: string;
	name: string;
	status: any;
	
	public formBuilder;
	
	public filterForm;
	public createForm;
	public createWDForm;
	public importForm;
	
	public importFile;
	
	public city_ids = [];
	
	public cityList;
	
	public isEdit = false;
	
	public errors = {};
	public createFormMessage: any;
	
	import_fields = {
			file:'',
			city_id:''
	}
	
	search_fields = {
			city_id:'0',
			search:''
	}
	form_fields = {
			city_id:'0',
			name:'',
			areas:'',
			status:1
	}
	
	public setFormBuilder(formBuilder){
		this.createFormMessage = false;
		
		this.formBuilder = formBuilder;
		this.filterForm = this.formBuilder.group(this.search_fields);
		
		this.createForm = this.formBuilder.group(this.form_fields);
		this.importForm = this.formBuilder.group(this.import_fields);
	}
	
	public getFilterForm()
	{
		return this.filterForm;
	}
	
	public getFilterdata()
	{
		return this.filterForm.value;
	}
	
	public getCreateForm()
	{
		return this.createForm;
	}
	
	public getFormdata()
	{
		return this.createForm.value;
	}
	
	public setCityList(list){
		this.cityList = list
	}
	
	
	public setErrors(errors){
		this.errors = errors;
	}
	
	public hasError(key)
	{
		return this.errors[key];
	}
	
	
	public createFormReset()
	{
		this.isEdit = false;
		this.createForm.controls['name'].enable();
		this.errors = {};
		this.createFormMessage = false;
		this.createForm.patchValue(this.form_fields);
		this.importForm.patchValue(this.import_fields);
	}	  
	
	public onImportFileSelect(input) {
		  if (input.files && input.files[0]) {
		    var reader = new FileReader();
		    reader.onload = (e: any) => {
		      this.importFile = e.target.result;
		      this.importForm.value.file = e.target.result;
		    }
		    reader.readAsDataURL(input.files[0]);
		  }
		}
}
