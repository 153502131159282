export class City {
	public id: string;
	name: string;
	status: any;
	answer:'';

	public answers = [];
	
	
	public formBuilder;
	
	public filterForm;
	public createForm;
	
	public userList;
	public supervisorList = [];
	public fwpList:any;
	
	public isEdit = false;
	
	public errors = {};
	public createFormMessage: any;
	
	search_fields = {
			search:''
	}
	form_fields = {
			city_manager:0,
			supervisor:0,
			user_id:0,
			name:'',
			type:'radio',
			option1:'',
			option2:'',
			option3:'',
			option4:'',
			answers:[]
	}
	
	
	
	public setFormBuilder(formBuilder){
		this.createFormMessage = false;
		
		this.formBuilder = formBuilder;
		this.filterForm = this.formBuilder.group(this.search_fields);
		
		this.createForm = this.formBuilder.group(this.form_fields);
	}
	
	public getFilterForm()
	{
		return this.filterForm;
	}
	
	public getFilterdata()
	{
		return this.filterForm.value;
	}
	
	public getCreateForm()
	{
		return this.createForm;
	}
	
	public getFormdata()
	{
		return this.createForm.value;
	}
	
	
	
	
	public setErrors(errors){
		this.errors = errors;
	}
	
	public hasError(key)
	{
		return this.errors[key];
	}
	
	public createFormReset()
	{
		this.isEdit = false;
		//this.createForm.controls['name'].enable();
		this.errors = {};
		this.createFormMessage = false;
		this.createForm.patchValue(this.form_fields);
		
	}	 
	
	//Assign Form
	changeCityManager(){
		if(this.createForm.value.city_manager > 0)
			this.supervisorList = this.userList[this.createForm.value.city_manager-1]['supervisor'];
	}
	
	
	public onAnswerChecklistChange(checked, checkbox) {
		if(this.createForm.value.type == 'radio'){
			this.answers = [];
		}
		
		if(checked)
		{	
			this.answers.push(checkbox);
		}
		else
		{
			var index = this.answers.indexOf(checkbox);
			if (index !== -1) this.answers.splice(index, 1);
		}
		this.createForm.value.answers = this.answers;
		console.log(this.answers);
	  }
}
