import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject,of, Observable } from 'rxjs';
import { HttpClient, HttpResponse, HttpParams,HttpErrorResponse} from '@angular/common/http';
import { config } from './../../config';
import { DataTablesResponse } from '../../models/common';
import { User } from './model';
import { NgForm, FormsModule, FormBuilder,FormControl  } from '@angular/forms';
import { catchError, mapTo, tap, map } from 'rxjs/operators';
import { DownloadService } from './../../download.service';

@Component({
  selector: 'app-data',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})


export class UserListComponent implements AfterViewInit, OnDestroy, OnInit {
	model = new User();
	dtResults: User[];
	filterForm;
	createForm;
	importForm;
	
	role = 5;
	create_user_text = "Admin"
	
	
	constructor(private http: HttpClient, private formBuilder: FormBuilder,public downloadService: DownloadService) {
	  this.model.setFormBuilder(formBuilder);
	  this.filterForm = this.model.getFilterForm();
	  this.createForm = this.model.getCreateForm();
	  this.importForm = this.model.getImportForm();
	  //this.model.createFormMessage = 'sss';
	}
	
	ngOnInit() {
		this.fetchCityList();
		this.getList();
		this.fetchUserList();
	}
	
	clearSearch(){
		this.model.filterForm.patchValue(this.model.search_fields);
		this.rerender();
	}
  	
	setRole(role){	 
		  this.role = role;
		  if(role == 1)this.create_user_text = "FWP";
		  else if(role == 2)this.create_user_text = "Supervisor";
		  else if(role == 3)this.create_user_text = "City Manager";
		  else if(role == 4)this.create_user_text = "IPM";
		  else if(role == 5)this.create_user_text = "Admin";
		  this.rerender();
	  }
	
	//Assign Form
	changeSupervisor(search:boolean = true){
		if(this.filterForm.value.supervisor != 0 || this.createForm.value.supervisor != 0){
			if(search){
				var supervisor = this.filterForm.value.supervisor;
				this.createForm.patchValue({supervisor: this.filterForm.value.supervisor});
			}
			else{
				var supervisor = this.createForm.value.supervisor;
				this.filterForm.patchValue({supervisor: this.createForm.value.supervisor});
			}
		}
	}
	
	getStockView(id){
		
	}
	
	//Import User fomr submit
	importUser() {
		  
		  var supervisor_id = this.model.importForm.value.supervisor;
		  
		  var parent_id = 0;
		  if(this.role == 1 && supervisor_id != 0)
        	  	  parent_id = supervisor_id;
		  else if(this.role == 2 && this.model.city_manager_id != 0)
        		  parent_id = this.model.city_manager_id
		  
    		  var r = {
				  'city_id': this.importForm.value.city_id,
				  'parent_id': parent_id,
    				  'user': this.model.importForm.value.file,
    				  'role': Number(this.role)
    		  };
		  
		  this.http.post(`${config.apiUrl}/user/import`,r).subscribe(resp => {
			  this.model.createFormMessage = "User has been imported.";
			  this.model.setErrors({});
			  this.rerender();
		  },(error: HttpErrorResponse)=> {
			  this.model.setErrors(error.error.error);
	      })
	  }
	
	getFormView(id){
		this.model.createFormReset();
		this.model.isEdit = true;
		
	    this.model.id = id;
		this.http.get(`${config.apiUrl}/user/`+id).subscribe(resp => {
				this.model.createForm.controls['code'].disable();
				this.model.createForm.controls['mobile'].disable();
				//resp['supervisor'] = resp['parent_id']
				this.model.createForm.patchValue(resp);
		  });
	}
	
	// Update parent Form
	//Assign outlet
	getParentForm(id){
		this.model.createFormReset();
		this.model.id = id;
		
	}
	updateParent()
	{
		var supervisor_id = this.model.parentForm.value.supervisor;
		var parent_id = 0;
        if(this.role == 1 && supervisor_id != 0)
        		parent_id = supervisor_id;
        else if(this.role == 2 && this.model.city_manager_id != 0)
        		parent_id = this.model.city_manager_id
        	
        	var data = {'parent_id': parent_id,'role':this.role}	
    		this.http.put(`${config.apiUrl}/user/`+ this.model.id,data).subscribe(resp => {
			  //console.log(resp);
			  this.model.createFormMessage = "User has been updated.";
			  this.model.setErrors({});
			  this.rerender();
			  this.model.city_manager_id = 0;
		  },(error: HttpErrorResponse)=> {
			  this.model.setErrors(error.error.error);
	      })
	}
	 // Create Form
	insertFormData() {
		var supervisor_id = this.model.filterForm.value.supervisor;
		var parent_id = 0;
        if(this.role == 1 && supervisor_id != 0)
        		parent_id = supervisor_id;
        else if(this.role == 2 && this.model.city_manager_id != 0)
        		parent_id = this.model.city_manager_id
        		
		var data = {}
		if(this.model.isEdit){
			data = {
				'city_id': this.createForm.value.city_id,
				'role': Number(this.role),
				'name': this.model.createForm.value.name,
				'email': this.model.createForm.value.email,
				//'code': this.model.createForm.value.code,
				//'mobile': Number(this.model.createForm.value.mobile),
				'status': Number(this.model.createForm.value.status),
				'isLocked': Number(this.model.createForm.value.isLocked),
				//'parent_id': parent_id
						
			 };
			 if(this.model.createForm.value.password != ''){
				 data['password'] = this.model.createForm.value.password;
				 data['confirmPassword'] = this.model.createForm.value.confirmPassword;
			 }
			 //console.log(data);
			 this.http.put(`${config.apiUrl}/user/`+ this.model.id,data).subscribe(resp => {
				  //console.log(resp);
				  this.model.createFormMessage = "User has been updated.";
				  this.model.setErrors({});
				  this.rerender();
			  },(error: HttpErrorResponse)=> {
				  this.model.setErrors(error.error.error);
		      })
		 }
		else{
			data = {
				'city_id': this.createForm.value.city_id,
				'role': Number(this.role),
				'name': this.model.createForm.value.name,
				'email': this.model.createForm.value.email,
				'code': this.model.createForm.value.code,
				'mobile': Number(this.model.createForm.value.mobile),
				'password': this.model.createForm.value.password,
				'confirmPassword': this.model.createForm.value.confirmPassword,
				'status': Number(this.model.createForm.value.status),
				'isLocked': Number(this.model.createForm.value.isLocked),
				'parent_id': parent_id
			 };
			  
			 // =console.log(data);
			 this.http.post(`${config.apiUrl}/user`,data).subscribe(resp => {
				  //console.log(resp);
				  this.model.createFormMessage = "User has been created.";
				  this.model.setErrors({});
			  },(error: HttpErrorResponse)=> {
				  this.model.setErrors(error.error.error);
		      })
		}
	 }
	
	 //For Filter form
	 onSearch(data) {
		  this.rerender();
	 }
	 
	
	 fetchUserList(): void {
		  this.http.get(`${config.apiUrl}/user/select`).subscribe(resp => {
			  this.model.userList = resp;
		  });
	 }
	 
	 fetchCityList(): void
	 {
		  this.http.get(`${config.apiUrl}/city/select`).subscribe(resp => {
			  this.model.setCityList(resp);
		  });
	 } 
	 
	 exportData(){
	 	var supervisor_id = this.model.filterForm.value.supervisor;
		var parent_id = 0;
        if(this.role == 1 && supervisor_id != 0)
        		parent_id = supervisor_id;
        else if(this.role == 2 && this.model.city_manager_id != 0)
        		parent_id = this.model.city_manager_id
        		
        var data = {'parent_id': parent_id,'role':this.role}	
		  
		this.http.post(`${config.apiUrl}/user/export`,data).subscribe(resp => {
				//console.log("hello");
				var link = document.createElement("a");
				link.download = "Users";
				link.href = `${config.apiUrl}/report/download/`+resp['filename'];
			    link.click();
		  });
	  }
	 
	 //DataTable Fetures start.............. 
	 getList(): void {
		  const that = this;
		  this.dtOptions = {
			  pagingType: 'full_numbers',
			  lengthChange: false,
			  pageLength: 10,
			  search: false,
			  serverSide: true,
			  processing: true,
			  order: [0,1,1],
			  ajax: (dataTablesParameters: any, callback) => {
			    dataTablesParameters.search = this.model.filterForm.value.search;
		        dataTablesParameters.role = this.role;
		        
		        var supervisor_id = this.model.filterForm.value.supervisor;
		        
		        if(this.role == 1 && supervisor_id != 0)
		        		dataTablesParameters.parent_id = supervisor_id;
		        else if(this.role == 2 && this.model.city_manager_id != 0)
		        		dataTablesParameters.parent_id = this.model.city_manager_id
			    
			    that.http.get<DataTablesResponse>(`${config.apiUrl}/user`, {params: dataTablesParameters }).subscribe(resp => {
			          that.dtResults = resp.data;
			
			          callback({
			            recordsTotal: resp.recordsTotal,
			            recordsFiltered: resp.recordsFiltered,
			            data: []
			          })
			      });
			  },
			  //columns: [{ data: 'id' },{ data: 'name' }, { data: 'code' },{ data: 'area' },{ data: 'zone' },{ data: 'address' }, { data: 'coardinates.latitude' },{ data: 'coardinates.longitude' },{ data: 'status'}],
			  columnDefs: [{orderable: false, targets: [0,1,2,3,4,5,6,7,8,9]}],
		  };
	  }
	 
	  @ViewChild(DataTableDirective, {static: false})
	  dtElement: DataTableDirective;
	  dtOptions: DataTables.Settings = {};
	  dtTrigger: Subject<any> = new Subject();
	  
	  ngAfterViewInit(): void { this.dtTrigger.next();}
	
	  ngOnDestroy(): void { this.dtTrigger.unsubscribe();}
	
	  rerender(): void { this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {dtInstance.draw();});}
	  //DataTable Fetures End.............. 
}
