export class Mip {
	public id: string;
	date: string;
	
	public formBuilder;
	
	public filterForm;
	public createForm;
	
	public errors = {};
	public createFormMessage: any;
	
	search_fields = {
			search:''
	}
	form_fields = {
			date:"2020-01-01"
	}
	
	
	
	public setFormBuilder(formBuilder){
		this.createFormMessage = false;
		
		this.formBuilder = formBuilder;
		this.filterForm = this.formBuilder.group(this.search_fields);
		
		this.createForm = this.formBuilder.group(this.form_fields);
	}
	
	public getFilterForm()
	{
		return this.filterForm;
	}
	
	public getFilterdata()
	{
		return this.filterForm.value;
	}
	
	public getCreateForm()
	{
		return this.createForm;
	}
	
	public getFormdata()
	{
		return this.createForm.value;
	}
	
	
	
	
	public setErrors(errors){
		this.errors = errors;
	}
	
	public hasError(key)
	{
		return this.errors[key];
	}
	
	public createFormReset()
	{
		this.errors = {};
		this.createFormMessage = false;
		this.createForm.patchValue(this.form_fields);
		
	}	  
	
	
}
