import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject,of, Observable } from 'rxjs';
import { HttpClient, HttpResponse, HttpParams,HttpErrorResponse} from '@angular/common/http';
import { config } from './../../config';
import { DataTablesResponse } from '../../models/common';
import { City } from './model';
import { NgForm, FormsModule, FormBuilder,FormControl  } from '@angular/forms';
import { catchError, mapTo, tap, map } from 'rxjs/operators';
import { DownloadFileService } from './../../download.service';

@Component({
  selector: 'app-data',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})


export class BroadcastListComponent implements AfterViewInit, OnDestroy, OnInit {
	model = new City();
	dtResults: City[]
	filterForm;
	createForm;
	resultView;
	downf = new DownloadFileService();
    
	constructor(private http: HttpClient, private formBuilder: FormBuilder) {
	  this.model.setFormBuilder(formBuilder);
	  this.filterForm = this.model.getFilterForm();
	  this.createForm = this.model.getCreateForm();
	}
	
	ngOnInit() {
		this.fetchUserList();
	    this.getList();
	}
  	
	getFormView(id){
		this.model.createFormReset();
		this.model.isEdit = true;
	    this.model.id = id;
		this.http.get(`${config.apiUrl}/broadcast/`+id).subscribe(resp => {
				//this.model.createForm.controls['name'].disable();
				this.model.createForm.patchValue(resp);
				this.model.answers = resp['answers'];
		  });
	}
	
	// Create Form
	insertFormData() {
		 //this.isSubmit = true;
		 var data = {}
		 
		 data = {
				 'user_id': this.createForm.value.supervisor,
				 'name': this.model.createForm.value.name,
				 'option1':this.model.createForm.value.option1,
				 'option2':this.model.createForm.value.option2,
				 'option3':this.model.createForm.value.option3,
				 'option4':this.model.createForm.value.option4,
				 'type': this.model.createForm.value.type,
				 'answers': this.model.createForm.value.answers
			 };
		  
		 //~ console.log(data);
		 this.http.post(`${config.apiUrl}/broadcast`,data).subscribe(resp => {
			  //console.log(resp);
			  this.model.createFormMessage = "Broadcast has been sent.";
			  this.model.setErrors({});
			  this.rerender();
		  },(error: HttpErrorResponse)=> {
			  this.model.setErrors(error.error.error);
	      })
	 }
	
	
	
	 //For Filter form
	 onSearch(data) {
		  this.rerender();
	 }
	 
	 fetchUserList(): void {
		  this.http.get(`${config.apiUrl}/user/select`).subscribe(resp => {
			  this.model.userList = resp;
		  });
	 } 
	
	 exportData(){
		 
	        var data = {}	
			this.http.post(`${config.apiUrl}/broadcast/export`,data, {responseType: 'blob'}).subscribe(resp => {  
			//~ this.http.post(`${config.apiUrl}/broadcast/export`,data).subscribe(resp => {
					//console.log("hello");
					//~ var link = document.createElement("a");
					//~ link.download = "Users";
					//~ link.href = `${config.apiUrl}/report/download/`+resp['filename'];
				    //~ link.click();
                    this.downf.downLoadFile(resp, " application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
			  },(error: HttpErrorResponse)=> {
                  $('#loader').hide();
            });
		  }
	 
	 //DataTable Fetures start.............. 
	 getList(): void {
		  const that = this;
		  this.dtOptions = {
			  pagingType: 'full_numbers',
			  lengthChange: false,
			  pageLength: 10,
			  search: false,
			  serverSide: true,
			  processing: true,
			  order: [0,1,1],
			  ajax: (dataTablesParameters: any, callback) => {
			    dataTablesParameters.search = this.model.filterForm.value.search;
			    
			    that.http.get<DataTablesResponse>(`${config.apiUrl}/broadcast`, {params: dataTablesParameters }).subscribe(resp => {
			          that.dtResults = resp.data;
			
			          callback({
			            recordsTotal: resp.recordsTotal,
			            recordsFiltered: resp.recordsFiltered,
			            data: []
			          })
			      });
			  },
			  //columns: [{ data: 'id' },{ data: 'name' }, { data: 'code' },{ data: 'area' },{ data: 'zone' },{ data: 'address' }, { data: 'coardinates.latitude' },{ data: 'coardinates.longitude' },{ data: 'status'}],
			  columnDefs: [{orderable: false, targets: [0,1,2,3,4,5,6,7,8]}],
		  };
	  }
	 
	  @ViewChild(DataTableDirective, {static: false})
	  dtElement: DataTableDirective;
	  dtOptions: DataTables.Settings = {};
	  dtTrigger: Subject<any> = new Subject();
	  
	  ngAfterViewInit(): void { this.dtTrigger.next();}
	
	  ngOnDestroy(): void { this.dtTrigger.unsubscribe();}
	
	  rerender(): void { this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {dtInstance.draw();});}
	  //DataTable Fetures End.............. 
	  
	  getResultView(id){
		  //this.activity_title = "Form View";
		  this.http.get(`${config.apiUrl}/broadcast/result/`+id).subscribe(resp => {
			  this.resultView = resp;
		  });
      }
	  
	  exportResult(id) {
            var data = {}	
			//~ this.http.post(`${config.apiUrl}/broadcast/result/`+id,data).subscribe(resp => {
            this.http.post(`${config.apiUrl}/broadcast/result/`+id, data, {responseType: 'blob'}).subscribe(resp => {
                //console.log("hello");
                //~ var link = document.createElement("a");
                //~ link.download = "Result";
                //~ link.href = `${config.apiUrl}/report/download/`+resp['filename'];
                //~ link.click();
                this.downf.downLoadFile(resp, " application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
            },(error: HttpErrorResponse)=> {
               $('#loader').hide();
            });
	        return false;
      }
	  
}
