import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject,of, Observable } from 'rxjs';
import { HttpClient, HttpResponse, HttpParams,HttpErrorResponse} from '@angular/common/http';
import { config } from './../../config';
import { DataTablesResponse } from '../../models/common';
import { Outlet } from './model';
import { NgForm, FormsModule, FormBuilder,FormControl  } from '@angular/forms';
import { catchError, mapTo, tap, map } from 'rxjs/operators';
import { DownloadFileService, DownloadService } from './../../download.service';

@Component({
  selector: 'app-data',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})


export class OutletListComponent implements AfterViewInit, OnDestroy, OnInit {
	model = new Outlet();
	dtResults: Outlet[];
	filterForm;
	createForm;
	importForm;
	assignForm;
    downf = new DownloadFileService();
	
	constructor(private http: HttpClient, private formBuilder: FormBuilder,public downloadService: DownloadService) {
	  this.model.setFormBuilder(formBuilder);
	  this.filterForm = this.model.getFilterForm();
	  this.createForm = this.model.getCreateForm();
	  this.importForm = this.model.getImportForm();
	  this.assignForm = this.model.getAssignForm();
	  //this.model.createFormMessage = 'sss';
	}
	
	ngOnInit() {
	  this.fetchCityList();
	  this.getList();
	  this.fetchUserList();
	  
	  //this.model.setErrors({'name':'Required Field'});
	  
	}
  	
	
	clearSearch(){
		this.model.filterForm.patchValue(this.model.search_fields);
		this.rerender();
	}
	
	//Assign outlet
	getAssignForm(id){
		this.model.createFormReset();
		this.model.assign_outlet_id = id;
	}
	
	//Assign Form
	changeSupervisor(){
		if(this.assignForm.value.supervisor != 0)
		{
			var supervisor = this.assignForm.value.supervisor;
			this.http.get(`${config.apiUrl}/user/select/`+supervisor).subscribe(resp => {
				  this.model.fwpList = resp;
			  });
		}
	}
	
	//Assign outlet
	assignOutlet(){
		  var r = {
				  'outlet_id': this.model.assign_outlet_id,
				  'user_id': this.assignForm.value.fwp,
				  'start_time': Number(this.assignForm.value.start_time),
				  'end_time': Number(this.assignForm.value.end_time),
				  'meeting': this.assignForm.value.meeting,
				  'activity': this.assignForm.value.activity,
				  'cycle': this.assignForm.value.cycle,
				  'tse': this.assignForm.value.tse,
				  'asm': this.assignForm.value.asm,
				  'contact': Number(this.assignForm.value.contact),
				  'community': this.assignForm.value.community,
		  };
		  
		  //console.log(r);
		  
		  this.http.post(`${config.apiUrl}/outlet/assign`,r).subscribe(resp => {
			  this.model.createFormMessage = "Outlet has been assigned.";
		  },(error: HttpErrorResponse)=> {
			  this.model.setErrors(error.error.error);
	      })
	}
	
	//Import Outlet fomr submit
	importOutlet() {
		  
		  var r = {
				  'city_id': this.importForm.value.city_id,
				  'outlet': this.model.importFile
		  };
		  
		  //console.log(r);
		  
		  this.http.post(`${config.apiUrl}/outlet/import`,r).subscribe(resp => {
			  console.log(resp);
			  this.model.createFormMessage = "Outlet has been imported.";
		  },(error: HttpErrorResponse)=> {
			  this.model.setErrors(error.error.error);
	      })
	  }
	
	getFormView(id){
		this.model.createFormReset();
		this.model.isEdit = true;
		
	    this.model.id = id;
		this.http.get(`${config.apiUrl}/outlet/`+id).subscribe(resp => {
				this.model.createForm.controls['code'].disable();
				resp['latitude'] = resp['coardinates']['latitude'].toString();
				resp['longitude'] = resp['coardinates']['longitude'].toString();
				this.model.createForm.patchValue(resp);
		  });
	}
	
	 // Create Form
	insertFormData() {
		
		var data = {}
		if(this.model.isEdit){
			data = {
				  'name': this.model.createForm.value.name,
				  //'code': this.createForm.value.code,
				  'area': this.model.createForm.value.area,
				  'zone': this.model.createForm.value.zone,
				  'address': this.model.createForm.value.address,
				  'coardinates': {'latitude':this.model.createForm.value.latitude,'longitude':this.model.createForm.value.longitude},
				  'status': Number(this.model.createForm.value.status),
				  'city_id': this.createForm.value.city_id,
				  'isVerified': Number(this.model.createForm.value.isVerified),
				  'type': this.model.createForm.value.type
			 };
			 this.http.put(`${config.apiUrl}/outlet/`+ this.model.id,data).subscribe(resp => {
				  //console.log(resp);
				  this.model.createFormMessage = "Outlet has been updated.";
				  this.model.setErrors({});
				  this.rerender();
			  },(error: HttpErrorResponse)=> {
				  this.model.setErrors(error.error.error);
		      })
		 }
		else{
			data = {
				  'name': this.model.createForm.value.name,
				  'code': this.model.createForm.value.code,
				  'area': this.model.createForm.value.area,
				  'zone': this.model.createForm.value.zone,
				  'address': this.model.createForm.value.address,
				  'coardinates': {'latitude':this.model.createForm.value.latitude,'longitude':this.model.createForm.value.longitude},
				  'status': Number(this.model.createForm.value.status),
				  'city_id': this.model.createForm.value.city_id,
				  'isVerified': Number(this.model.createForm.value.isVerified),
				  'type': this.model.createForm.value.type
			 };
			  
			 //console.log(data);
			 this.http.post(`${config.apiUrl}/outlet`,data).subscribe(resp => {
				  //console.log(resp);
				  this.model.createFormMessage = "Outlet has been created.";
				  this.model.setErrors({});
			  },(error: HttpErrorResponse)=> {
				  this.model.setErrors(error.error.error);
		      })
		}
	 }
	
	
	
	 //For Filter form
	 onSearch(data) {
		  this.rerender();
	 }
	 
	 changeCity(filter){
		  //this.model.changeCity(filter);
		  if(filter)
		  this.rerender();
	 }
	 changeIsVerified(filter){
		  if(filter)
		  this.rerender();
	 }
	 changeStatus(filter){
		  if(filter)
		  this.rerender();
	 }
	 
	 fetchUserList(): void {
		  this.http.get(`${config.apiUrl}/user/select`).subscribe(resp => {
			  this.model.userList = resp;
		  });
	 }
	  
	 fetchCityList(): void
	 {
		  this.http.get(`${config.apiUrl}/city/select`).subscribe(resp => {
			  this.model.setCityList(resp);
		  });
	 }  
  
	 exportData() {
		 
	        var data = {'city_id': this.filterForm.value.city_id}	
			  
			this.http.post(`${config.apiUrl}/outlet/export`, data, {responseType: 'blob'}).subscribe(resp => {
			//~ this.http.post(`${config.apiUrl}/outlet/export`,data).subscribe(resp => {
					//console.log("hello");
					//~ var link = document.createElement("a");
					//~ link.download = "Users";
					//~ link.href = `${config.apiUrl}/report/download/`+resp['filename'];
				    //~ link.click();
                    this.downf.downLoadFile(resp, " application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
            },(error: HttpErrorResponse)=> {
                $('#loader').hide();
            });
		  }
  
	 //DataTable Fetures start.............. 
	 getList(): void {
		  const that = this;
		  this.dtOptions = {
			  pagingType: 'full_numbers',
			  lengthChange: false,
			  pageLength: 10,
			  search: false,
			  serverSide: true,
			  processing: true,
			  order: [0,1,1],
			  ajax: (dataTablesParameters: any, callback) => {
			    dataTablesParameters.search = this.model.filterForm.value.search;
			    dataTablesParameters.city_id = this.filterForm.value.city_id;
			    
			    that.http.get<DataTablesResponse>(`${config.apiUrl}/outlet`, {params: dataTablesParameters }).subscribe(resp => {
			          that.dtResults = resp.data;
			
			          callback({
			            recordsTotal: resp.recordsTotal,
			            recordsFiltered: resp.recordsFiltered,
			            data: []
			          })
			      });
			  },
			  //columns: [{ data: 'id' },{ data: 'name' }, { data: 'code' },{ data: 'area' },{ data: 'zone' },{ data: 'address' }, { data: 'coardinates.latitude' },{ data: 'coardinates.longitude' },{ data: 'status'}],
			  columnDefs: [{orderable: false, targets: [0,1,2,3,4,5,6,7,8,9,10,11]}],
		  };
	  }
	 
	  @ViewChild(DataTableDirective, {static: false})
	  dtElement: DataTableDirective;
	  dtOptions: DataTables.Settings = {};
	  dtTrigger: Subject<any> = new Subject();
	  
	  ngAfterViewInit(): void { this.dtTrigger.next();}
	
	  ngOnDestroy(): void { this.dtTrigger.unsubscribe();}
	
	  rerender(): void { this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {dtInstance.draw();});}
	  //DataTable Fetures End.............. 
}
